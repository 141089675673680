import { Box, IconButton, Typography } from '@material-ui/core';
import { GridApiRef, GridCellParams } from '@mui/x-data-grid-pro';

interface EditableCellProps {
  className: string;
  apiRef: GridApiRef;
  editable?: boolean;
  highlightField?: boolean;
}

export function EditableCell(props: EditableCellProps & GridCellParams) {
  const {
    value,
    className,
    id,
    field,
    apiRef,
    editable = true,
    highlightField = false,
  } = props;

  return (
    <Box
      sx={{
        bgcolor: highlightField && editable ? '#e3dddd' : '',
        textAlign: 'left',
        paddingLeft: '5px',
      }}
      className={className}
    >
      <Typography>{value}</Typography>
      {editable && (
        <IconButton
          onClick={() => {
            apiRef.current.setCellMode(id, field, 'edit');
          }}
        ></IconButton>
      )}
    </Box>
  );
}
