import { Button, Tooltip, Typography } from '@material-ui/core';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

interface CustomGridToolbarProps {
  onGetTFTExcelClicked: () => void;
  onExportTFTExcelClicked: () => void;
}

export function TftCustomGridToolbar(props: CustomGridToolbarProps) {
  const { onGetTFTExcelClicked, onExportTFTExcelClicked } = props;
  const { t } = useTranslation();

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarFilterButton
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarDensitySelector
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <Button
        style={{ color: '#69bc46' }}
        startIcon={<SaveAltIcon />}
        color="primary"
        size="small"
        onClick={onGetTFTExcelClicked}
      >
        {t('actions.get', { item: 'Excel' })}
      </Button>
      <Button
        style={{ color: '#69bc46' }}
        startIcon={<SaveAltIcon />}
        color="primary"
        size="small"
        onClick={onExportTFTExcelClicked}
      >
        {t('actions.export', { item: 'filtered excel' })}
      </Button>
      <Tooltip title={`${t('attributes.sortingInfo')}`}>
        <Typography style={{ marginLeft: 7, fontSize: 13 }}>
          {`(i)${t('attributes.sortingIconText')}`}
        </Typography>
      </Tooltip>
    </GridToolbarContainer>
  );
}
