import CheckIcon from '@material-ui/icons/Check';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export function TrueOrFalseCellWithNoClearIcon(props: GridRenderCellParams) {
  const { value } = props;
  if (value) {
    return <CheckIcon />;
  }

  return <></>;
}
export function TrueOrFalseCellWithBoolean(val: any) {
  if (typeof val == 'object' && val.val == true) {
    return <CheckIcon />;
  }

  return <></>;
}
