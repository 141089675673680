import { Grid, Paper, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';
import { ListFixedTrafficRoutes } from '../components/ListFixedTrafficRoutes';
import {
  FixedTrafficLeg,
  Subcontractor,
  useGetFixedTrafficLegsQuery,
} from '../generated/graphql';

interface FixedTrafficOverviewProps {
  terminal: {
    id: number;
    name: string;
  };
}

export function FixedTrafficOverview(props: FixedTrafficOverviewProps) {
  const { terminal } = props;
  const { t } = useTranslation();
  const [gridData, setGridData] = useState<
    Array<
      { __typename?: 'FixedTrafficLeg' } & Pick<
        FixedTrafficLeg,
        | 'id'
        | 'routeId'
        | 'tourId'
        | 'capacity'
        | 'days'
        | 'arrivalTime'
        | 'departureTime'
        | 'departureCity'
        | 'arrivalCity'
        | 'load'
        | 'unload'
        | 'note'
        | 'startDate'
      > & {
          subcontractor: { __typename?: 'Subcontractor' } & Pick<
            Subcontractor,
            'id' | 'name'
          >;
        }
    >
  >([]);
  const { data, loading } = useGetFixedTrafficLegsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      terminalId: terminal.id,
    },
  });
  useEffect(() => {
    if (
      data &&
      !loading &&
      data.fixedTrafficLegs &&
      data.fixedTrafficLegs.length > 0
    ) {
      data.fixedTrafficLegs.forEach((record) => {
        setGridData((prevState) => [
          ...prevState,
          { ...record, subcontractorName: record.subcontractor.name },
        ]);
      });
    }
  }, [data, loading]);
  return (
    <Grid container spacing={3} style={{ marginTop: 2 }}>
      <HelmetComponent title={t('pages.fixedTrafficOverview.title')} />
      <Grid item xs={12}>
        <Typography variant="h1">
          {`${t('pages.fixedTrafficOverview.title')} ${terminal?.name || ''}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          {terminal && (
            <ListFixedTrafficRoutes loading={loading} legs={gridData} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
