import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translation: {
        pages: {
          switch: {
            title: 'NEXUS',
            homePageIntro: 'Route Management System',
            ingress: 'This is the future nexus application',
            links: {
              traffic: 'To Traffic',
              terminal: 'To Terminal',
              customs: 'To Customs',
            },
            guideLinks: {
              whatIsNew: 'What is New',
              howToGetAccess: 'How to Get Access',
              learnMore: 'Learn More',
            },
          },
          login: {
            intro: ' Please sign-in to see your profile information.',
            login: ' Log in',
          },
          terminal: {
            title: 'Terminal',
            ingress: 'This is for terminal users.',
            selectTerminalTitle: 'Choose your terminal:',
            name: 'Your Terminal is: {{name}}',
          },
          fixedTrafficOverview: {
            title: 'Fixed Traffic Overview for: ',
          },
          traffic: {
            title: 'Traffic',
            ingress: 'This is for traffic users.',
          },
          customs: {
            title: 'Customs',
            ingress: 'This is for customs users.',
          },
          customer: {
            title: 'Customer',
            ingress: 'This is for customer users.',
          },
          reports: {
            title: 'Reports',
            inProgress: 'under construction',
          },
          editPage: {
            title: 'Edit {{card}}',
          },
          addPage: {
            title: 'Add {{item}}',
          },
          createPage: {
            title: 'Create {{card}}',
            details: 'Details',
          },
          documentBank: {
            title: 'Document Bank',
          },
          holidays: {
            addComponentTitle: 'Add deviation date',
            upcomingComponentTitle: 'Planned deviated dates for {{country}}',
            strictlyCancel: 'Strictly cancel / Do not generate',
          },
          cmr: {
            title: 'CMR',
          },
          truckFillAndTime: {
            title: 'Truck fill and time - {{name}}',
          },
          kpi: {
            title: 'KPI',
          },
          kpiOrdering: {
            title: 'KPI Ordering',
          },
          dashboard: {
            title: 'Dashboard',
          },
          deviations: {
            title: 'Deviations',
          },
          customerDeviations: {
            title: 'Customer Level Deviations',
          },
          template: {
            title: 'Templates - Create New',
          },
        },
        components: {
          files: 'Files',
          notes: 'Notes',
        },
        validation: {
          atLeastItem: 'Atleast One Item is required',
          atLeastLen: '{{name}} should be at least {{len}} characters',
          atLeastOneDay: 'At least one day needs to be set',
          duplicateRouteName: 'Duplicate Route Name not allowed',
          deletedLocation:
            'This location in card is no longer exists, please update accordingly',
          minNumber: '{{name}} must be greater than or equal to {{len}}.',
          noDecimals: '{{name}} can´t have decimals.',
          isRequired: '{{name}} is required',
          onlyNumbers: '{{name}} should only contain numbers',
          onlyAlphaNumeric: '{{name}} should be AlphaNumeric [without space]',
          fileTooBig: 'File too large',
          onlyPDF: 'Only PDF supported',
          confirmation: 'Are you sure you want to {{action}} {{item}}?',
          couldNotFind: "Couldn't find the {{item}} you were looking for",
          isRequiredIf: "{{name}} required when {{other}} is set'",
          copyRouteAlert: 'Where do you want to copy the route {{item}} ?',
          lastRoute: 'Tour Template must have at least one route!',
          lastCustomerLocation:
            'Customer deviation must have at least one location!',
          deleteConfirmation: 'Are you sure you want to delete ',
          unlockConfirmation: 'Are you sure you want to unlock ',
          unlockWarning:
            'This will refresh the route page and unsaved changes may get discarded!',
          fieldPositive: '{{name}} must be a positive number',
          noRemainingGoods: 'Remaining goods data not found!',
          noTerminalSelected: 'No terminal is selected!',
          selectedTerminals: 'Selected Terminals:',
          selectClassification: 'Select Classification',
          noLegRoutesData: 'No routes data found for selected date range!',
          loadingApi: 'Loading...',
          routesAllTerminals:
            'Routes - Avg. Filled factors + Total PPLs [All Terminals]',
          totalRoutesSuccess: 'Total {{total}} routes created successfully!',
          totalRoutesDeleted: 'Total {{total}} routes deleted successfully!',
          noDataForAll: 'No data found for all terminals!',
          routesOneHighLow: 'Routes - Filled factors [Lowest & Highest]',
          uploadSuccess: 'Uploaded successfully!',
          plannedRoutesSuccess: 'Routes created successfully!',
          plannedRoutesErrorDate:
            'Please check exception dates, seems it is same as date range!',
          plannedRoutesErrorRange:
            'Date range is too large, please choose range within 3 months!',
          uploadError: 'Error while uploading!',
          updatingRoute: 'Updating route...',
          pingBy: 'Edit request - Ping by',
          routeUpdated: 'Route updated!',
          routeSameContent: 'Already saved - No changes found!',
          routeLeaveConfirmation:
            'You may have unsaved changes, Are you sure you want to leave?',
          checklistLeaveConfirmation:
            "[If you are sure to leave this page, please 'Cancel' this prompt and leave safely after clicking on 'Unlock All' button] You may have unsaved changes, Are you sure you want to leave anyway?",
          limitedQtyInfoTitle: 'Limited Quantity - Info',
          unNumber: 'Enter UN Number',
          unNumberLength: 'UN Number must be of 4 digit',
          un4DigitNumber: '4 Digit UN Number',
          alertImportSuccess: 'Imported {{count}} {{type}} successfully!',
          importFailed: 'Server error, Import failed!',
          noDataInExcelImport: 'No data imported, Duplicate rows!',
          invalidExcelFormat: 'Invalid file template / card type!',
          emptyRowInSheet: 'Seems empty file, No data found to import!',
          improperFileForImport:
            'Please make sure file extension is .xlsx and size is less than {{sizeInMB}} MB',
          improperImageFileForUpload:
            'Please select Image file and size should be less than {{sizeInMB}} MB',
          improperDocFileForUpload:
            'Please select proper file and size should be less than {{sizeInMB}} MB',
          limitedQtyInfo:
            'The limited quantity is the maximum quantity per inner packaging or article for the transport of dangerous goods in limited quantities and is subject to these regulations:\n' +
            '  > The dangerous goods must not exceed the Limited Quantity defined for the UN number.\n' +
            '  > The dangerous goods must be packed in an inner and outer packaging.\n' +
            '  > The packaging does not have to have design approval, but must protect the goods from damage during transport.\n' +
            '  > The total gross mass of the package must not exceed 30 kg, the total gross mass of trays must not exceed 20 kg.\n' +
            '  > The outer packaging must bear a marking for limited quantities.\n' +
            '  > The transport document must contain the entry “Dangerous goods in limited quantity + gross weight”.\n' +
            '  > The driver must be trained in accordance with the ADR 1.3 dangerous goods regulations.\n',
          updateError:
            'Entered seal number already exists, please enter another seal number',
          loadingListError:
            'Leg with load is not found, please check your route',
          loadingListServerError:
            'Failed to fetch the loading list. try again!',
          timeFormatError: 'Please enter proper time',
          required: 'Required',
          plsSelectTerminal: 'Please select a terminal to view routes data',
          selectTerminal: 'Please select terminal to view info',
          noShipmentFound:
            'No shipment found for current terminal, Try changing the date.',
          wrongGridHeader: '* <Total> is not allowed in RowHeader name',
          noNextLegAvailable: 'CMR is not Available for this route',
          loadPPLIsAbove100: "PPL factor can't be more than 100% ",
          noCMRFound: 'CMR is not available for this route',
          atLeastOneFile: 'At least one file must be added',
          noFilesAttached: 'No file is attached - Be adviced!',
          invalidEmailWithComma:
            'Email string contains multiple commas without valid emails between them.',
          validEmail: 'All emails are valid',
          invalidEmail: 'Invalid Email',
          invlaidDateTime: 'Date time is either Invalid or Blank',
          endWithComma: 'Email string ends with a comma',
          tooManyEmails: 'Maximum 10 Emails user can enter for Manual Alerts',
          noLoadingListAvailable: 'No Loadinglist Available!',
        },
        button: {
          copy: 'Copy {{item}}',
          create: 'Create {{item}}',
          change: 'Change {{item}}',
          update: 'Update {{item}}',
          edit: 'Edit {{item}}',
          add: 'Add {{item}}',
          save: 'Save {{item}}',
          remove: 'Remove {{item}}',
          get: 'Get {{item}}',
          cancel: 'Cancel',
          export: 'Export',
          view: 'View {{item}}',
          download: 'Download {{item}}',
          back: 'Back',
          close: 'Close',
          confirmAndCopy: 'Confirm and Copy',
          confirmAndLink: 'Confirm and Link',
          closeItem: 'Close {{item}}',
          delete: 'Delete {{item}}',
          continue: 'Continue',
          logOut: 'Log out',
          ok: 'OK',
          yes: 'Yes',
          saveChanges: 'Save changes',
          unlockAll: 'Unlock all',
          viewHighLow: 'View Highest and Lowest',
          viewTFT: 'View TFT Grid',
          tftOverview: 'TFT OVERVIEW',
          allCancelled: 'All Cancelled',
          onlySporadic: 'View Sporadic',
          unlink: 'Unlink',
        },
        resource: {
          accounts: {
            plural: 'Accounts',
            lowercased: 'account',
          },
          associatedRoutes: {
            lowercased: 'associated routes',
            capitalized: 'Associated Routes',
            active: 'Associated Routes (Active)',
          },
          bookmark: {
            lowercased: 'bookmark',
            capitalized: 'Bookmark',
            plural: 'Bookmarks',
          },
          card: {
            lowercased: 'card',
            capitalized: 'Card',
            plural: 'Cards',
          },
          checkpoint: {
            lowercased: 'checkpoint',
            capitalized: 'Checkpoint',
            plural: 'Checkpoints',
          },
          checklistRoute: {
            capitalized: 'Checklist route',
            plural: 'Checklist routes',
          },
          cmr: {
            lowercased: 'cmr',
            capitalized: 'CMR',
            plural: 'CMRs',
          },
          contact: {
            lowercased: 'contact',
            capitalized: 'Contact',
            plural: 'Contacts',
          },
          costAllocation: {
            lowercased: 'cost allocation',
            capitalized: 'Cost allocation',
            plural: 'Cost allocations',
          },
          customer: {
            lowercased: 'customer',
            capitalized: 'Customer',
            plural: 'Customers',
          },
          customs: {
            lowercased: 'customs',
            capitalized: 'Customs',
          },
          deviation: {
            lowercased: 'deviation',
            capitalized: 'Deviation',
            plural: 'Deviations',
          },
          documentFile: {
            lowercased: 'file',
            capitalized: 'File',
            plural: 'Files',
          },
          dispatchedRoute: {
            lowercased: 'dispatched route',
            capitalized: 'Dispatched route',
            plural: 'Routes',
          },
          extraInbound: {
            capitalized: 'Extra Inbound',
          },
          extraOutbound: {
            capitalized: 'Extra Outbound',
          },
          grid: {
            capitalized: 'Add Grid',
          },
          productionDate: {
            lowercased: '(production date)',
          },
          noInboundOutbound: {
            capitalized: 'No Inbound / Outbound sporadic routes for this date',
          },
          noInbound: {
            capitalized: 'No Inbound sporadic routes for this date',
          },
          noOutbound: {
            capitalized: 'No Outbound sporadic routes for this date',
          },
          noInfoAdded: {
            capitalized: 'No Information added!',
          },
          noBoxAdded: {
            capitalized: 'No Box to display!',
          },
          noGridAdded: {
            capitalized: 'No Grid to display!',
          },
          notDispatched: {
            capitalized: '{{item}}(Not Dispatched)',
          },
          dateManagement: {
            capitalized: 'Date Management',
            notice:
              'Only applicable to Fixed tourRoutes, Single/Multiple Sporadic will be still generated.',
          },
          legDetails: {
            capitalized: 'Leg Details',
          },
          loadingList: {
            lowercased: 'loading list',
            capitalized: 'Loading list',
            plural: `Loading list's`,
          },
          loadingListItem: {
            lowercased: 'loading list item',
            capitalized: 'Loading list Item',
            plural: `Loading list's items`,
          },
          location: {
            lowercased: 'location',
            capitalized: 'Location',
            plural: 'Locations',
          },
          locationList: {
            lowercased: 'location list',
            capitalized: 'Location List',
            plural: 'Locations List',
          },
          order: {
            lowercased: 'order',
            capitalized: 'Order',
            plural: 'Orders',
          },
          peakDebit: {
            lowercased: 'peak debit',
            capitalized: 'Peak debit',
          },
          peakPlanning: {
            lowercased: 'peak planning',
            capitalized: 'Peak planning',
          },
          route: {
            lowercased: 'route',
            capitalized: 'Route',
            plural: 'Routes',
          },
          routeFtl: {
            lowercased: 'FTL',
            capitalized: 'FTL',
            plural: 'FTL',
          },
          routeLeg: {
            lowercased: 'leg',
            capitalized: 'Leg',
            plural: 'Legs',
          },
          shipment: {
            lowercased: 'Shipment',
            template: 'Template',
          },
          sporadicRoute: {
            lowercased: 'sporadic route',
            capitalized: 'Sporadic route',
            plural: 'Sporadic routes',
            abbreviation: 'SR',
            add: 'Add Sporadic Route',
          },
          plannedRoute: {
            lowercased: 'planned sporadic routes',
            capitalized: 'Planned Sporadic routes',
            plural: 'Planned routes',
          },
          all: {
            lowercased: 'all',
            capitalized: 'All',
            plural: 'All',
          },
          subcontractor: {
            lowercased: 'subcontractor',
            capitalized: 'Subcontractor',
            plural: 'Subcontractors',
          },
          identity: {
            capitalized: 'Identity',
          },
          template: {
            lowercased: 'template',
            capitalized: 'Template',
            plural: 'Templates',
          },
          terminal: {
            lowercased: 'terminal',
            capitalized: 'Terminal',
            plural: 'Terminals',
          },
          tour: {
            lowercased: 'tour',
            capitalized: 'Tour',
            plural: 'Tours',
          },
          reportToTerminal: {
            capitalized: 'Report to terminal',
          },
          viewReportFromTraffic: {
            capitalized: 'Report from traffic',
          },
          reportFor: {
            capitalized: 'Report from traffic for ',
          },
          reportNoData: {
            inOut: '-- No Inbound/Outbound routes found for selected date! --',
            in: '-- No Inbound routes found for selected date! --',
            out: '-- No Outbound routes found for selected date! --',
          },
          reportRouteData: 'No routes data found for selected date range',
          tourSchedule: {
            lowercased: 'tour schedule',
            capitalized: 'Tour schedule',
            plural: 'Tour schedules',
          },
          traffic: {
            lowercased: 'traffic',
            capitalized: 'Traffic',
          },
          valueBoxes: {
            capitalized: 'VALUE BOXES',
          },
          previousStop: {
            capitalized: 'Previous stop',
          },
          nextStop: {
            capitalized: 'Next stop',
          },
          viewLoadingList: {
            capitalized: 'Loading List',
          },
        },
        validationError: {
          string: {
            min: 'Needs to be at least ${min} characters',
            required: 'Is required',
          },
          number: {
            min: 'Value needs to be more than ${min}',
            required: 'Is required',
          },
        },
        attributeGrouping: {
          invoice: 'Invoice',
          driver: 'Driver',
          agreement: 'Agreement',
          note: 'Note',
          dates: 'Dates',
        },
        enums: {
          deviationCause: {
            LeftoverCargo: 'Leftover cargo',
            TrafficIssues: 'Traffic issues',
            DelayedFerry: 'Delayed ferry',
            TechnicalIssueOnEquipmentOrTruck:
              'Technical issue on equipment or truck',
            CustomsIssues: 'Customs issues',
            LateDeparture: 'Late departure',
            PoliceControl: 'Police control',
          },
          deviationFault: {
            Customer: 'Customer',
            Terminal: 'Terminal',
            Subcontractor: 'Subcontractor',
            ExternalPart: 'External part',
            BringCustoms: 'Bring (Customs)',
            WeatherConditions: 'Weather conditions',
            AddedFromTerminal: 'Added from terminal',
            FaultFromTerminal: 'Bring (Terminal)',
            Other: 'Other',
          },
          dangerousGoodsClass: {
            Explosives: 'Explosives',
            Gases: 'Gases',
            FlammableLiquids: 'Flammable Liquids',
            FlammableSolids: 'Flammable Solids',
            OxidizingSubstancesandOrganicPeroxides:
              'Oxidizing Substances and Organic Peroxides',
            ToxicandInfectiousSubstances: 'Toxic and Infectious Substances',
            RadioactiveMaterial: 'Radioactive Material',
            CorrosiveSubstances: 'Corrosive Substances',
            MiscellaneousDangerousSubstancesandArticles:
              'Miscellaneous Dangerous Substances and Articles',
          },
          remainingGoodsReasonClass: {
            LackOfDocuments: 'Lack of Documents',
            LackOfCapacity: 'Lack of Capacity',
            LateArrivalToTerminal: 'Late Arrival to Terminal',
            DamagedItems: 'Damaged Items',
            Other: 'Other',
            LeftoverCargo: 'Leftover cargo',
            TrafficIssues: 'Traffic issues',
            DelayedFerry: 'Delayed ferry',
            TechnicalIssueOnEquipmentOrTruck:
              'Technical issue on equipment or truck',
            CustomsIssues: 'Customs issues',
            LateDeparture: 'Late departure',
            PoliceControl: 'Police control',
            MissingEdi: 'Missing EDI',
            LateProduction: 'Late Production',
            PlannedOnNextDayRoutes: 'Planned on next day Routes',
            DangerousGoods: 'Dangerous goods',
            RedChannel: 'Red channel',
          },
          tourRouteTemplate: {
            SingleSporadic: 'Single Sporadic',
            MultipleSporadic: 'Multiple Sporadic',
            Fixed: 'Fixed',
            Old: 'Old',
          },
          customsProcedure: {
            T1: 'T1',
            T2: 'T2',
            TVINN: 'TVINN',
            VOEC: 'VOEC',
            LETTERVOLUME: 'LETTER VOLUME',
          },
        },
        attributes: {
          alert: 'Alert',
          active: 'Active',
          accountNumber: 'Account number',
          actualCapacity: 'Capacity',
          address: 'Address',
          additionalCost: 'Additional Cost',
          additionalDiscount: 'Additional Discount',
          addLeg: 'Add Leg',
          addTruck: 'Add Truck',
          addTruckLoading: 'truck - Loading',
          addTruckUnloading: 'truck - Unloading',
          addDate: 'Add date to create shipment',
          adr: 'ADR',
          agreedPrice: 'Agreed price',
          agreement: 'Agreement',
          agreementNumber: 'Agreement number',
          agreementPdf: 'Agreement pdf',
          and: 'and',
          arrivalCity: 'Arrival city',
          arrivalCountry: 'Arrival country',
          arrivalDate: 'Arrival date',
          arrivalLocation: 'Arrival location',
          arrivalTime: 'ArrivalTime',
          assignedStatus: 'Assigned Status',
          firstLegArrivalTime: 'First leg arrival time ',
          firstLegDepartTIme: 'First leg departure time',
          lastLegArrivalTime: 'Last leg arrival time',
          lastLegDepartTime: 'Last leg departure time',
          arrivalTimeAbbreviated: 'A.T.',
          actualArrivalTime: 'ArrivalTime',
          actualArrTime: 'Actual arrival time',
          allGroups: 'All groups',
          alternateGenerateDate: 'Alternate Date',
          booked: 'Booked',
          bookedPPL: 'Booked PPL',
          box: 'Box',
          boxName: 'Box name',
          bringCost: 'Bring Cost',
          tag: 'Tag',
          tagName: 'Tag name',
          capacity: 'Capacity',
          capacityAbbreviated: 'CAP',
          car: {
            upperCased: 'CAR',
            lowerCased: 'car',
            capitalized: 'Car',
          },
          carRegistrationNumber: 'Car Registration Number',
          registrationNumber: 'Registration Number',
          routeCarRegNumber: 'Car Registration Number',
          cause: 'Cause',
          causeReason: 'Cause/Reason',
          city: 'City',
          cdc: 'CDC',
          cmr: 'CMR',
          chartTerminalWise: 'Terminalwise chart',
          chartCustomerWise: 'Customerwise chart',
          checkListRoutesTitle: 'Checklist - Dispatched Routes',
          checkLists: 'Checklist',
          checkDangerGood: 'Check DangerGoods?',
          clearedFromCustom: 'Export cleared',
          clearedFromCustomImport: 'Import cleared',
          clickToDownload: 'Click here to download',
          clickToDelete: 'Click here to delete',
          comment: 'Comment',
          compactRowHeight: 'Compact Row Height',
          contactType: 'Contact type',
          consequence: 'Consequence',
          copyRoute: 'Copy route',
          copyToOtherEmptyRows: 'Copy to other rows',
          cost: 'Cost',
          country: 'Country',
          createdBy: 'Creator',
          currency: 'Currency',
          ftlCurrency: 'FTL currency',
          customer: 'Customer',
          customerCost: 'Customer cost',
          customerCurrency: 'Customer cost currency',
          customerId: 'Customer',
          customerNumber: 'Customer number',
          customerReference: 'Customer reference',
          customerCheck: 'Need of check from customer',
          customVOECId: 'customVOECId',
          customInvoiceNo: 'customInvoiceNo',
          customMrnNo: 'customMrnNo',
          customWaybillNo: 'customWaybillNo',
          customCmrNo: 'customCmrNo',
          customNote: 'customNote',
          connectedNB: 'connectedNB',
          copyDriverPlateInfo: 'Copy below details to each leg',
          currentTour: 'Current tour',
          cmrNo: 'CMR No:',
          connectedNo: 'Connected No:',
          dangerGoodFound: 'Some/One route here, contains Dangerous goods',
          dangerGoodNotFound: 'No Dangerous goods found in any Routes',
          date: 'Date',
          days: 'Days',
          debit: 'Debit',
          debitCount: 'Debit count',
          deleteSelected: 'Delete selected',
          deliveryDate: 'Delivery date',
          department: 'Department',
          departureCity: 'Departure city',
          departureCountry: 'Departure country',
          departureDate: 'Departure date',
          departureLocation: 'Departure location',
          departureTime: 'DepartureTime',
          departureTimeAbbreviated: 'D.T.',
          destinationCountry: 'Destination country',
          deviationType: 'Deviation type',
          deviationApplicableDate: 'Date',
          difference: 'Difference',
          dispatcherId: 'Dispatcher',
          discount: 'Discount',
          dividePriceEqually: 'Divide price equally',
          dontHaveTemplate: "Don't have template file?",
          driverInfo: 'DriverInfo',
          driverRead: 'DriverInfo - ReadOnly',
          driverName: 'Driver name',
          driverPhoneNumber: 'Driver phone number',
          dynamicRowHeight: 'Dynamic Row Height',
          exit: 'Exit (esc)',
          fullscreen: 'Fullscreen (ctrl + f)',
          reason: 'reason',
          routeDriverName: 'Driver Name',
          routeDriverPhoneNumber: 'Driver PhoneNumber',
          endTime: 'Select end time for filter',
          email: 'Email',
          emailSent: 'Email sent',
          externalNote: 'External Note',
          exportReport: 'Production Report',
          endCreateDate: 'End create date',
          endExceptionDate: 'End exception date',
          endDate: 'End date',
          failConfigMessage: 'Cannot save configuration',
          fault: 'Source',
          faultLocationId: 'From location',
          fetchProductionGroup: 'Production groups?',
          file: 'File',
          fileTagIds: 'File tags',
          filterGroup: 'Filter Type',
          ftlInvoiceNote: 'FTL invoice note',
          filteredRowsCSV: 'filtered rows csv',
          forTransportDate: 'For TransportationDate',
          frequency: 'Frequency',
          fromCountry: 'From country',
          fromLocationId: 'From Location',
          fromToToCountry: 'From-To country',
          filter: 'Filter',
          gateNumber: 'Gate Number',
          generateByDateRange: 'Generate By Selecting Date Range:',
          getExcel: 'Get Excel',
          grid: 'Grid',
          gridNameLabel: 'Enter name (max. 15 char)',
          gridRowsLabel: 'Enter number of rows',
          gridColumnsLabel: 'Enter maximum 10 columns',
          rowHeader: 'Add Row Headers (maximum 15 characters)',
          columnHeader: 'Add Column Headers (maximum 10 characters)',
          groupingTag: 'Group tag',
          goodsInfo: 'Goods Info -',
          name: 'Name',
          nonArrival: 'Non-arrival',
          nameWithResource: '{{item}} Name',
          fromTerminal: 'From location',
          fuel: 'Fuel',
          hasExceptionDates: 'Add exception dates',
          chooseFromCalendar: 'Choose from Calendar',
          id: 'Id',
          includedKpi: 'Included KPI',
          includedPrice: 'Included Price',
          info: 'Info',
          routeGridInfo:
            'Info: click save config button to save table configuration',
          originalStartDate: 'Original Start Date',
          orderIsLinked: 'Linked from Shipment, Not editable.',
          invoiceNote: 'Invoice note',
          invoiceNumber: 'Invoice number',
          turnummer: 'turnummer',
          togref: 'togref',
          bpx: 'bpx',
          pri03: 'pri03',
          pri49: 'pri49',
          upri03: 'upri03',
          upri49: 'upri49',
          parti: 'parti',
          brev: 'brev',
          pru: 'pru',
          tom: 'tom',
          rutekode: 'Rutekode',
          firstLegDate: 'First leg date',
          lastLegDate: 'Last leg date',
          firstLegTerminalName: 'First leg terminal',
          lastLegTerminalName: 'Last leg terminal',
          inCarLoad: 'Load in CAR',
          inTrailerLoad: 'Load in TRAILER',
          isLoadingInCar: 'Load in CAR',
          isLoadingInTrailer: 'Loading In Trailer',
          trainNumber: 'TrainNumber',
          containerId: 'Container Id',
          transportType: 'Transport Type',
          inneholdsbeskrivelse: 'Inneholdsbeskrivelse',
          kilometer: 'KM',
          lastUpdatedBy: 'Last updated by',
          licensePlate: 'License plate',
          licensePlateAndNote: 'License plate & Note',
          limitedQty: 'Limited Quantity',
          LC1: 'LC1',
          LC2: 'LC2',
          load: 'Load',
          loadCarrier: 'Load Carrier',
          loadEnd: 'Load End',
          loadingLocation: 'Loading location',
          loadStart: 'Load start',
          location: 'Location',
          locationId: 'Location',
          locationCode: 'Location Code',
          logisticPerson: 'Logistic person',
          logisticPersonName: 'Logistic person name',
          locationType: 'Location Type',
          headedTerminalId: 'Terminal',
          multipleCustomers: 'Multiple Customers',
          myPlannedRoutes: 'My Planned Routes',
          mrnNo: 'MRN No:',
          note: 'Note',
          newCustomer: 'New customer',
          otherTour: 'Other tour',
          otherReason: 'Other reason',
          internalNote: 'Internal note',
          noteWithResource: '{{resource}} Note',
          numbersGrid:
            'Create Numbers Grid with Total row (Row count must > 1)',
          reset: 'Reset',
          saveLegs: 'Save Leg(s)',
          successMsg: 'Successfully Done',
          successSavedMsg: 'Successfully saved',
          receivedStatus: 'Received Status',
          errorWhileSavingChangesMsg: 'Error while saving the changes',
          errorWhileUpdatingPosition: 'Error while updating the leg position',
          text: 'Text',
          terminal: 'Terminal',
          terminalCleared: 'Cleared from Terminal',
          terminalNotCleared: 'Deviation from Terminal',
          trailerRegistrationNumber: 'Trailer Registration Number',
          routeTrailerRegNumber: 'Trailer Registration Number',
          routeName: 'Route Name',
          templateName: 'Template Name',
          transprtationDate: 'Transport Date',
          title: 'Title',
          toTerminal: 'To location',
          toLocationId: 'To location',
          totalOfEverything: 'Overall Cost ',
          totalBringCost: 'Total BringCost',
          totalNewAgreedPrice: 'New AgreedPrice',
          totalAdditionalCost: 'Total AdditionalCost',
          tourTemplateWarn: `*Updated template data will reflect in 'FixedRoute' from the day after tomorrow`,
          totalRows: 'Total Rows: {{ rowCount }}',
          type: 'Type',
          pastDate: 'ReadOnly due to past date',
          packages: 'Packages',
          pallets: 'Pallets',
          palletSpace: 'Pallet Space',
          percentage: 'Percentage',
          phLocationId: 'PH Location ID',
          phoneNumber: 'Phone number',
          postalCode: 'Postal code',
          price: 'Price',
          pricePercentage: 'PricePercentage',
          productionDate: 'Production date',
          productCountry: 'Product country',
          productType: 'Product type',
          productionGroup: 'Production group',
          readOnly: 'Read-only mode',
          receiverLocationId: 'Receiver location',
          reloadLegDetails: 'Please reload leg details',
          reloadLoadingListDetails: 'Please reload loading list details',
          remainingGoods: 'Remaining Goods',
          responsible: 'Responsible',
          responsiblePerson: 'Responsible person',
          replyToInfo: 'Reply to info',
          reportNotUploaded: 'Report yet not uploaded!',
          role: 'Role',
          routeId: 'Route Id',
          routeGroupTag: 'Route Group Tag',
          routeDriverInfo: 'Driver & LicensePlate Info',
          sameDayBoxInfo: `Check this to only see one date in range. 
          Uncheck this if you want to see todays date in Start and tomorrows date in End.
          Time will not be affected but end time can not be earlier than start time if 24h Prod. is checked`,
          saveConfigSuccessMessage: 'Configuration saved successfully',
          sameProdDay: '24h Prod.',
          selectTerminals: 'Select Terminals',
          salesContact: 'Sales contact',
          salesPerson: 'Sales person',
          salesPersonName: 'Sales person name',
          selfCost: 'Self cost',
          selfCostCurrency: 'Self cost currency',
          selectedDates: 'Selected dates:',
          selectedExcDates: 'Selected Exception dates:',
          senderLocationId: 'Sender location',
          sentPPL: 'Sent PPL',
          showingYourRoutes: 'Showing routes created by you on',
          shipmentNotArrived: 'Shipment not arrived',
          startCreatedDate: 'Start create date',
          startDate: 'Start date',
          startTime: 'Select start time for filter',
          startExceptionDate: 'Start exception date',
          subcontractorId: 'Subcontractor',
          subcontractorTwoId: 'Other Subcontractor',
          status: 'Status',
          toCountry: 'To country',
          tourIdSuffix: 'Tour Id suffix',
          trailer: {
            upperCased: 'TRAILER',
            lowerCased: 'trailer',
            capitalized: 'Trailer',
          },
          transportDate: 'Transport. date',
          transportationDate: 'Transportation date',
          transportationDateOffset: 'Trans.Date offset',
          unload: 'Unload',
          unloaded: 'Unloaded',
          unusedCapacity: 'Unused capacity',
          updatedAt: 'Last update date',
          updateErrorOnDeletedLeg: `The leg can't be updated as it was already deleted`,
          errorOnLoadingListAsLegDeleted: `can't add/delete/update loading list as leg was deleted`,
          updateErrorOnLoadingListAsDeleted: `The loading list can't be updated as it was already deleted`,
          utilization: 'Utilization',
          warningMessage: 'Please select one',
          weight: 'Weight',
          weightKg: 'Weight (KG)',
          localMessageCustomers: 'Message to customer(local)',
          englishMessageCustomers: 'English message to customers',
          registeredStatus: 'Registered',
          unregisteredStatus: 'Unregistered',
          cancelledStatus: 'Cancelled',
          view: 'View',
          viewSporadicRoutes: 'View Sporadic Routes',
          viewPlannedRoutes: 'View Planned Routes',
          viewFilledItemsReport: 'View Filled items report',
          viewRemainingGoodsReport: 'View Remaining goods report',
          partialRegisteredStatus: 'Partially Registered',
          viewBoxAndInfo: 'View Box and Info report',
          warningOfDangerousGoods:
            '- Leg contains dangerous goods, Advice personnel at terminal.',
          tooltipDangerousGoodsIcon: 'Mark/Unmark the goods item as dangerous',
          tooltipDangerousGoodsIconReadOnly: 'Indicates dangerous goods!',
          tooltipEDA: 'Estimated Date of Arrival',
          tooltipETA: 'Estimated Time of Arrival',
          tooltipETD: 'Estimated time of Departure',
          tooltipActualArrivalDate: 'Actual Arrival Date',
          tooltipActualArrivalTime: 'Actual Arrival Time',
          tooltipActualDepartureDate: 'Actual Departure Date',
          tooltipActualDepartureTime: 'Actual Departure Time',
          tooltipLimitedQtyInfo: 'Limited Qty Info',
          checkList_tft: 'All trucks in TFT checked [Based on date range]',
          checkList_remGoods: 'Documented all remaining goods',
          checkList_vBox: 'All KPIs are filled',
          checkList_info: 'Relevant information have been noted',
          checkList_title: 'Production report - Checklist items',
          uploadNow: 'Upload Now',
          hideRegistered: 'Hide Registered',
          pastDateReadOnly: 'Read only due to past date',
          showRegistered: 'Show Registered',
          sortingInfo:
            'INFO: For multiple column sorting - Press [Ctrl] and than click sort icon of particular column in Grid',
          sortingIconText: 'Sorting tip',
          routesUpdated: 'Routes updated successfully',
          routesUnlocked: 'Routes unlocked successfully',
          readOnlyDueToShipImported:
            'Read only due to one of the orders is linked to route',
          rememberToUnlock: 'Kindly remember to unlock below routes | Total',
          transDate: 'Trans. date',
          group: 'Group',
          unlockRoutesPls:
            'Kindly unlock below routes first to start editing all',
          pingUser: 'Ping user',
          unlockAnyway: 'Unlock anyway',
          un: 'UN-',
          sureToUnlockAll:
            'Are you sure? Kindly make sure you saved all changes before unlocking.',
          wait: 'wait...',
          checkValidation: 'Check validations and try again',
          groupLocked:
            'Locked by Bulk Checklist Page, Only user who has locked can unlock.',
          createNewChecklist: 'Create New Checklist',
          routeIsLocked: 'Can not edit, this route is locked',
          routeLockedBySelf: 'Route is locked by: You ',
          tftTitle: 'Truck Fill and Time - Overview',
          customsTFTTitle: 'TFT Overview',
          prodReportTitle: 'Production Report',
          reportRemainingGoods: 'Remaining Goods',
          reportFilledFactors: 'Statistics',
          reportInformation: 'Information',
          frontPage: 'Front Page',
          untilDate: 'Create until',
          viewSporadicOnly: 'View only sporadic legs',
          viewAllLegs: 'View all cancelled legs of both type',
          waybill: 'Waybill:',
          voecId: 'VOEC ID:',
          loadingListNotFound: 'Loading list not added',
          moDoneStatus: 'MoDone Status',
        },
        country: {
          sweden: 'Sweden',
          denmark: 'Denmark',
          norway: 'Norway',
        },
        countryInitial: {
          sweden: 'SE',
          denmark: 'DK',
          norway: 'NO',
          finland: 'FI',
          netherland: 'NL',
        },
        filter: {
          date: 'Date',
          startDate: 'Start Date',
          endDate: 'End Date',
          inboundDomestic: 'In. Domestic',
          outboundDomestic: 'Out. Domestic',
          inboundImport: 'Import',
          outboundExport: 'Export',

          search: 'Search',
        },
        view: {
          routeView: 'Route View',
          infoView: 'Info View',
          gridView: 'Grid View',
          valueBoxesView: 'Value Boxes View',
        },
        weekDays: {
          mon: 'Mon',
          tue: 'Tue',
          wed: 'Wed',
          thu: 'Thu',
          fri: 'Fri',
          sat: 'Sat',
          sun: 'Sun',
        },
        states: {
          loading: 'loading',
        },
        actions: {
          add: 'Add {{item}}',
          apply: 'Apply',
          contact: 'Contact',
          save: 'Save',
          deleteTour: 'Delete entire Tour:',
          delTour: 'Delete Tour',
          delShipment: 'delete shipment',
          delLeg: 'Delete Leg',
          deleteLoadingListMessage: 'Loading list is successfully deleted',
          delete: {
            lowercased: 'delete',
            capitalized: 'Delete',
          },
          remove: {
            lowercased: 'remove',
            withItem: 'Remove {{item}}',
          },
          removeBookmark: {
            lowercased: 'remove bookmark of ',
          },
          cancel: 'Cancel',
          copyTour: 'Copy Tour',
          createShipment: 'Create Shipment',
          downloadImage: 'Click to download',
          edit: 'Edit',
          editItem: 'Edit {{item}}',
          update: 'Update',
          updateAndExit: 'Update & Exit',
          uploadImage: 'Click to upload',
          view: 'View',
          viewItem: 'View {{item}}',
          viewItemSwitched: '{{item}} view',
          export: 'Export {{item}}',
          import: 'Import {{item}}',
          get: 'Get {{item}}',
          create: {
            lowercased: 'create',
            capitalized: 'Create',
          },
          debit: 'Debit',
          dispatchRoute: 'Manual Dispatch',
          goTo: 'Go to {{item}}',
          goBack: 'Go back',
          open: 'Open',
          close: 'Close {{item}}',
          download: 'Download {{item}}',
          downloadReport: 'Download Report',
          moveItem: 'Move {{item}} {{direction}}',
          left: 'left',
          right: 'right',
          promote: 'Promote {{item}}',
          demote: 'Demote {{item}}',
          send: 'Send {{item}}',
          unlock: 'Unlock',
          unlockAnyway: 'Unlock Anyway',
          ping: 'Ping',
          refresh: 'Refresh',
          tftOverviewNorway: 'TFT Overview - NO',
          tourId: 'Tour Id is null',
          unlink: 'Un-link',
          managefiles: 'Manage Files',
        },
        feedback: {
          created: '{{item}} created',
          alreadyCreated: '{{item}} already created',
        },
        info: {
          uploadImage: 'Upload Image',
          uploadDocument: 'Upload Document',
          importCardData: 'Import Card Data',
          fileUploadMessage:
            'Upload Excel file [Max size {{maxFileSizeInMB}} MB]',
          imageUploadMessage:
            'Upload image file [Max size {{maxFileSizeInMB}} MB]',
          docUploadMessage:
            'Upload valid document file [Max size {{maxFileSizeInMB}} MB]',
          fileWillBeReplaced:
            '-If you will upload again, existing file will be replaced.',
          fileExistHere: 'One file exist [{{fileName}}] --> ',
          bookmarkedDispatchRoutes: 'Bookmarked Dispatch Routes',
          note: 'Note',
          noReportRecieverContactAvailable:
            'No Report Reciever contact available! would you like to Send Manually?',
          manualDispatchNote:
            'If the route is dispatched on a day that is not selected in the schedule, it will generate as a sporadic route instead of a dispatch.',
          manualDispath: {
            createdSporadic:
              'This route has been dispatched for {{date}} as a sporadic route. Would you like to open it?',
            createdFixed:
              'This route has been dispatched for {{date}}. Would you like to open it?',
            alreadyCreated:
              'This route is already been dispatched for this date. Would you like to open it?',
          },
          enterMultipleEmail: 'Enter multiple emails separated by a comma ,',
          sendManualAlertSuccess: 'Alert has been sent successfully',
          loading: 'Loading, please wait...',
          routeCompleted: 'Route completed',
          readyForDepartureFrom: 'Ready for departure from',
          onRouteFor: 'On Route for',
          arrivedAt: 'Arrived at',
        },
        dialog: {
          userConfig:
            'This will remove all setting for tables, such as column width, density, and resetting hidden/visible columns to default, are you sure?',
          location: `Adding a new location will remove the existing location, are you sure?`,
          chooseLocation: 'Choose the location you want to add a debit to',
          email: 'Send Alerts Manually.',
        },
        menu: {
          changeTerminal: 'Change Terminal',
          clearConfig: 'Clear user configuration',
          changeDepartment: 'Change department to',
          collapseTruckSections: 'Collapse truck sections',
        },
        gridColumns: {
          EDA: 'EDA',
          ETA: 'ETA',
          ETD: 'ETD',
          KG: 'KG',
          PAL: 'PAL',
          PCS: 'PCS',
          PPL: 'PPL',
          actualArrivalDate: 'Actual Arrival Date',
          actualArrivalTime: 'Actual Arrival Time',
          actualDepartureDate: 'Actual Departure Date',
          actualDepartureTime: 'Actual Departure Time',
          arrDate: 'Arr. Date',
          arrTime: 'Arr. Time',
          capacity: 'Capacity',
          carCapacity: 'Car Capacity',
          carContainerId: 'Car Container ID',
          carGate: 'Car Gate',
          carId: 'Car ID',
          checked: 'Checked',
          checkpointDate: 'Checkpoint Date',
          checkpointLocation: 'Checkpoint Location',
          checkpointTime: 'Checkpoint Time',
          contactInfo: 'Contact Info',
          country: 'Country',
          customerORItem: 'Customer/Item',
          delete: 'Delete',
          depDate: 'Dep. Date',
          depTime: 'Dep. Time',
          dispatcher: 'Dispatcher',
          distanceInKM: 'Distance In KM',
          downloadAgreementPDF: 'Download Agreement pdf',
          driver: 'Driver',
          endDate: 'End Date',
          endLocation: 'End Location',
          fromCountryToCountry: 'From Country - To Country',
          fuelType: 'Fuel Type',
          goodsInfo: 'Goods Info',
          hasDangerousGoods: 'Has Dangerous Goods',
          importedFromShipment: 'Imported from Shipment',
          legNote: 'Leg Note',
          limitedQuantity: 'Limited Quantity',
          location: 'Location',
          locationType: 'Location Type',
          moDone: 'MO Done',
          note: 'Note',
          price: 'Price',
          responsiblePerson: 'Responsible Person',
          routeName: 'Route Name',
          routeType: 'Route Type',
          startLocation: 'Start Location',
          status: 'Status',
          startDate: 'Start Date',
          subcontractor: 'Subcontractor',
          templateName: 'Template Name',
          templateType: 'Template Type',
          templateNote: 'Template Note',
          tourTags: 'Tour Tags',
          trailerCapacity: 'Trailer Capacity',
          trailerContainerId: 'Trailer Container ID',
          trailerGate: 'Trailer Gate',
          trailerId: 'Trailer ID',
          transportDate: 'Transport Date',
          transportType: 'Transport Type',
          transportationDateOffset: 'Date Offset',
          unNumber: 'UN Number',
          viewAgreementPDF: 'View Agreement pdf',
          wayBillNo: 'Way Bill No',
          weightInKG: 'Weight (In KGs)',
        },
      },
    },
  },
});

export { i18n };
