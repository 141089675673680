import { ApolloError } from '@apollo/client';
import {
  Box,
  Checkbox,
  createStyles,
  Link,
  makeStyles,
  Tooltip,
  Typography,
  Snackbar,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  DataGridPro,
  getGridStringOperators,
  GridCellEditCommitParams,
  GridCellParams,
  GridColumns,
  GridColumnVisibilityModel,
  GridFilterInputValue,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridRenderCellParams,
  GridRowId,
  GridSortModel,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Alert } from '@material-ui/lab';
import { format, parse } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';
import {
  DocumentFile,
  RouteType,
  useDeleteAttachmentFileMutation,
  useTruckFillAndTimeMutation,
  useRefetchRouteLegLazyQuery,
  useUpdateTftConfigurationMutation,
  useGetTftConfigQuery,
} from '../generated/graphql';
import { COUNTRIES_MAP, TABLE_NAMES } from '../lib/constants';
import { DATE_FORMAT, timeOrNull } from '../lib/date_time';
import { EditableCell } from './datagrid/EditableCell';
import { TimePickerEditCell } from './datagrid/TimePickerEditCell';
import { TrueOrFalseCellWithNoClearIcon } from './datagrid/TrueOrFalseCellWithNoClearIcon';
import { CustomColumnsPanel } from './datagrid/UserConfiguredDataGridPro';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import RefreshIcon from '@material-ui/icons/CachedOutlined';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import CancelledIcon from '@mui/icons-material/HighlightOff';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { UploadFileModal } from './modal/UploadFileModal';
import { useHttpClient } from '../providers/HttpClientProvider';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ToysIcon from '@mui/icons-material/Toys';
import { getLoadingList } from '../components/DownloadLoadingList';
import { TerminalTimeInfo } from './datagrid/TerminalTimeInfoModal';
import { LoadingListModal } from './datagrid/LoadingListModal';
import { QRModal } from './datagrid/QRModal';
import { SelectDate } from './SelectDate';
import { DownloadCMR } from './datagrid/DownloadCMRCell';
import { TftSelectDate } from './TftSelectDate';
import { TftCustomGridToolbar } from './TFTCustomGridToolBar';
import * as XLSX from 'xlsx';

const filterOperatorsCountry: GridFilterOperator[] = [
  ...getGridStringOperators(),
  {
    label: 'is not',
    value: 'isNot',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params): boolean => {
        return params.value !== filterItem.value;
      };
    },
    InputComponent: GridFilterInputValue,
  },
];

const useStylesIncomingRoutes = makeStyles(() =>
  createStyles({
    editableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& p:first-child': {
        maxWidth: '75%',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        inlineSize: '75%',
      },
    },
    iconStyle: {
      padding: '0 !important',
    },
    muiOdd: {
      backgroundColor: '#e7e7e7',
    },
    muiEven: {
      backgroundColor: '#fff',
    },
    muiLateStatus: {
      '&:hover': {
        background: '#e89992 !important',
      },
      backgroundColor: '#f5c6c6',
    },
  }),
);

const RegisteredChip = withStyles({
  root: {
    color: '#12de63',
    borderColor: '#12de63',
  },
})(Chip);

const UnregisteredChip = withStyles({
  root: {
    color: '#f07267',
    borderColor: '#f07267',
  },
})(Chip);

const CancelledChip = withStyles({
  root: {
    color: '#fc3926',
    borderColor: '#fc3926',
  },
})(Chip);

const PartialRegisteredChip = withStyles({
  root: {
    color: '#eda528',
    borderColor: '#eda528',
  },
})(Chip);

interface TerminalLocation {
  id: number;
  country: string;
}

interface ListIncomingRoutesProps {
  gridTop: number;
  isHideRegistered: boolean;
  terminal: {
    id: number;
  };
  legs?: {
    id: number;
    arrivalTime: string;
    departureTime?: Maybe<string>;
    actualArrivalTime?: Maybe<string>;
    actualArrivalDate?: Maybe<string>;
    actualDepartureTime?: Maybe<string>;
    actualDepartureDate?: Maybe<string>;
    productionDate: string;
    transportationDate: string;
    actualCapacity?: Maybe<number>;
    position?: number;
    loadCar?: Maybe<number>;
    loadCages?: Maybe<number>;
    loadTrailer?: Maybe<number>;
    unloadCar?: Maybe<number>;
    unloadCages?: Maybe<number>;
    unloadTrailer?: Maybe<number>;
    loadCarPPL?: Maybe<number>;
    loadTrailerPPL?: Maybe<number>;
    unloadCarPPL?: Maybe<number>;
    unloadTrailerPPL?: Maybe<number>;
    arrivingPallets?: Maybe<number>;
    arrivingPalletSpace?: Maybe<number>;
    timeObj?: {
      originalArrTime: string;
      actualArrTime: string;
      originalDepartTime: string;
      actualDepartTime: string;
      terminalName: string;
      legId: number;
      transportationDate: string;
    }[];
    note: string;
    unload: boolean;
    load: boolean;
    gateNumber?: Maybe<string> | undefined;
    carRegistrationNumber?: Maybe<string> | undefined;
    trailerRegistrationNumber?: Maybe<string> | undefined;
    terminalNickname?: Maybe<string> | undefined;
    terminalNote: string;
    lastUpdatedBy?: Maybe<string>;
    updatedAt: string;
    isNonArrivalMarked?: boolean;
    nonArrivalMarkedFrom?: Maybe<string>;
    isSealBroken?: Maybe<boolean>;
    sealNumber?: Maybe<string> | undefined;
    productionGroup?: Maybe<string> | undefined;
    terminalResponsible?: Maybe<string> | undefined;
    arrivalStatus?: Maybe<string> | undefined;
    turnummer?: Maybe<number> | undefined;
    togref?: Maybe<string> | undefined;
    bpx?: Maybe<number> | undefined;
    pri03?: Maybe<number> | undefined;
    pri49?: Maybe<number> | undefined;
    upri03?: Maybe<number> | undefined;
    upri49?: Maybe<number> | undefined;
    parti?: Maybe<number> | undefined;
    brev?: Maybe<number> | undefined;
    pru?: Maybe<number> | undefined;
    tom?: Maybe<boolean> | undefined;
    rutekode?: Maybe<string> | undefined;
    trainNumber?: Maybe<number> | undefined;
    inneholdsbeskrivelse?: Maybe<string> | undefined;
    containerId?: Maybe<string> | undefined;
    transportType?: Maybe<string> | undefined;
    isLoadingInCar?: boolean;
    isLoadingInTrailer?: boolean;
    carDetails?: {
      actualCapacity?: Maybe<number> | undefined;
      registrationNumber?: Maybe<string> | undefined;
      gateNumber?: Maybe<string> | undefined;
    };
    trailerDetails?: {
      actualCapacity?: Maybe<number> | undefined;
      registrationNumber?: Maybe<string> | undefined;
      gateNumber?: Maybe<string> | undefined;
    };
    files: Pick<
      DocumentFile,
      'id' | 'size' | 'path' | 'mimetype' | 'originalname'
    >[];
    loadingListItems: {
      pallets?: Maybe<number>;
      palletSpace?: Maybe<number>;
      isDangerous: boolean;
    }[];
    location?: Maybe<{
      id: number;
      city: string;
      country: string;
    }>;
    route: {
      id: number;
      isCreatedFromTerminal: boolean;
      isCreatedFromPlanned: boolean;
      type: RouteType;
      routeId: string;
      capacity?: Maybe<number>;
      routeCreatedBy: string;
      routeLastUpdatedBy: string;
      routeUpdatedAt: Date;
      routeDeletedAt: Date;
      routeDeletedBy: string;
      tourRoute?: Maybe<{
        tourId: number;
      }>;
      transportationDate: string;
      driverName: string;
      trailerCarrierId: string;
      carCarrierId: string;
      subcontractor: {
        id: number;
        name: string;
      };
      legs: {
        position?: Maybe<number>;
        unload: boolean;
        load: boolean;
        location?: Maybe<{
          id: number;
          name: string;
          city: string;
          country: string;
        }>;
      }[];
    };
  }[];
  terminalLocation?: TerminalLocation;
  loading: boolean;
  error?: ApolloError;
  group?: string;
  onGetTFTExcelClicked: () => void;
}

export interface Filters {
  inboundDomestic: boolean;
  outboundDomestic: boolean;
  inboundImport: boolean;
  outboundExport: boolean;
}

export function ListIncomingRoutes(props: ListIncomingRoutesProps) {
  const classes = useStylesIncomingRoutes();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [updateTftConfig] = useUpdateTftConfigurationMutation();
  const { data: TftConfigData, refetch: refetchTftConfig } =
    useGetTftConfigQuery();
  const [filteredRowsData, setFilteredRowsData] = useState({});
  const [totalObj, setTotalObj] = useState({
    totalLoadPallet: 0,
    totalLoadPalletSpace: 0,
    totalUnloadPallet: 0,
    totalUnloadPalletSpace: 0,
  });
  const [height] = useState(
    window.innerHeight - (props.gridTop + props.gridTop / 5),
  );
  const [errorInfo, setErrorInfo] = useState<{
    errorInField: boolean;
    errorMessage: string;
  }>();
  const [autoHeights, setAutoHeights] = useState<boolean>(false);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [showUploadLoading, setShowUploadLoading] = useState<boolean>(false);
  const [extraData, setExtraData] = useState<{
    routeName: string;
    legId: number;
    subcontractorName: string;
    existingFiles: DocumentFile[];
  }>();
  const { httpClient } = useHttpClient();

  //Filter model of Grid
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });
  const [configStatus, setConfigStatus] = useState({
    status: '',
  });
  const [configStoreLoading, setConfigStoreLoading] = useState(false);
  const {
    legs,
    loading,
    error,
    terminal,
    terminalLocation,
    group,
    onGetTFTExcelClicked,
  } = props;
  const rows = buildRows(legs, terminalLocation);

  const setVisibilityModelDefault = (): GridColumnVisibilityModel => {
    //set default column visibility based on table/grid
    return {
      id: false,
      newArrivalTime: false,
      arrivalCountry: false,
      departureCountry: false,
      inboundFlag: false,
      outboundFlag: false,
      importFlag: false,
      exportFlag: false,
      carCarrierId: false,
      carCarrierIdQR: false,
      trailerCarrierId: false,
      trailerCarrierIdQR: false,
    };
  };

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'transportationDate',
      sort: 'asc',
    },
    {
      field: 'arrivalTime',
      sort: 'asc',
    },
  ]);
  const [gridColumnVisibilityModel, setGridColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(setVisibilityModelDefault());
  const [columnOrder, setColumnOrder] = useState([]);
  const [refetchLatestLeg] = useRefetchRouteLegLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [deleteAttachmentFileMutation] = useDeleteAttachmentFileMutation();
  const [dataGrid, setDataGrid] = useState(rows ?? []);
  const [registeredCount, setRegisteredCount] = useState(0);
  const [truckFillAndTimeMutation] = useTruckFillAndTimeMutation();

  React.useEffect(() => {
    if (_.isEmpty(filteredRowsData)) {
      //filter not applied
      if (rows && rows.length > 0 && (!group || group == 'All-Groups')) {
        let records = rows;
        if (props.isHideRegistered) {
          const rowWithStatus = rows?.map((obj) => ({
            ...obj,
            statusStr: getChipStatus(obj).toString(),
          }));

          records = rowWithStatus.filter(
            (i) =>
              i.statusStr === t('attributes.unregisteredStatus').toString() ||
              i.statusStr ===
                t('attributes.partialRegisteredStatus').toString() ||
              i.statusStr === 'N/A',
          );
        }

        const totalLoadPallets =
          (_.sumBy(records, 'loadCar') ?? 0) +
          (_.sumBy(records, 'loadTrailer') ?? 0);
        const totoalLoadPalletSpace =
          (_.sumBy(records, 'loadCarPPL') ?? 0) +
          (_.sumBy(records, 'loadTrailerPPL') ?? 0);
        const totalUnloadPallets =
          (_.sumBy(records, 'unloadCar') ?? 0) +
          (_.sumBy(records, 'unloadTrailer') ?? 0);
        const totalUnloadPalletSpace =
          (_.sumBy(records, 'unloadCarPPL') ?? 0) +
          (_.sumBy(records, 'unloadTrailerPPL') ?? 0);

        setTotalObj({
          totalLoadPallet: totalLoadPallets,
          totalLoadPalletSpace: totoalLoadPalletSpace,
          totalUnloadPallet: totalUnloadPallets,
          totalUnloadPalletSpace: totalUnloadPalletSpace,
        });
      } else if (rows && group && group !== 'All-Groups') {
        const legsWithGroup = rows.filter(
          (leg) => leg.productionGroup === group,
        );
        let records = legsWithGroup;
        if (props.isHideRegistered) {
          const rowWithStatus = rows?.map((obj) => ({
            ...obj,
            statusStr: getChipStatus(obj).toString(),
          }));

          records = rowWithStatus.filter(
            (i) =>
              i.statusStr === t('attributes.unregisteredStatus').toString() ||
              i.statusStr ===
                t('attributes.partialRegisteredStatus').toString() ||
              i.statusStr === 'N/A',
          );
        }

        const totalLoadPallets =
          (_.sumBy(records, 'loadCar') ?? 0) +
          (_.sumBy(records, 'loadTrailer') ?? 0);
        const totoalLoadPalletSpace =
          (_.sumBy(records, 'loadCarPPL') ?? 0) +
          (_.sumBy(records, 'loadTrailerPPL') ?? 0);
        const totalUnloadPallets =
          (_.sumBy(records, 'unloadCar') ?? 0) +
          (_.sumBy(records, 'unloadTrailer') ?? 0);
        const totalUnloadPalletSpace =
          (_.sumBy(records, 'unloadCarPPL') ?? 0) +
          (_.sumBy(records, 'unloadTrailerPPL') ?? 0);

        setTotalObj({
          totalLoadPallet: totalLoadPallets,
          totalLoadPalletSpace: totoalLoadPalletSpace,
          totalUnloadPallet: totalUnloadPallets,
          totalUnloadPalletSpace: totalUnloadPalletSpace,
        });
      } else {
        setTotalObj({
          totalLoadPallet: 0,
          totalLoadPalletSpace: 0,
          totalUnloadPallet: 0,
          totalUnloadPalletSpace: 0,
        });
      }
    } else {
      if (rows && rows.length > 0) {
        //filter is applied
        const visibleItems: any[] = [];
        for (const [id, value] of Object.entries(filteredRowsData)) {
          if (value === true) {
            visibleItems.push(id);
          }
        }
        const filteredRowsOnly = rows.filter((item) =>
          visibleItems.includes(item.id?.toString()),
        );
        if (filteredRowsOnly) {
          const totalLoadPallets =
            (_.sumBy(filteredRowsOnly, 'loadCar') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'loadTrailer') ?? 0);
          const totoalLoadPalletSpace =
            (_.sumBy(filteredRowsOnly, 'loadCarPPL') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'loadTrailerPPL') ?? 0);
          const totalUnloadPallets =
            (_.sumBy(filteredRowsOnly, 'unloadCar') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'unloadTrailer') ?? 0);
          const totalUnloadPalletSpace =
            (_.sumBy(filteredRowsOnly, 'unloadCarPPL') ?? 0) +
            (_.sumBy(filteredRowsOnly, 'unloadTrailerPPL') ?? 0);
          setTotalObj({
            totalLoadPallet: totalLoadPallets,
            totalLoadPalletSpace: totoalLoadPalletSpace,
            totalUnloadPallet: totalUnloadPallets,
            totalUnloadPalletSpace: totalUnloadPalletSpace,
          });
        } else {
          setTotalObj({
            totalLoadPallet: 0,
            totalLoadPalletSpace: 0,
            totalUnloadPallet: 0,
            totalUnloadPalletSpace: 0,
          });
        }
      } else {
        setTotalObj({
          totalLoadPallet: 0,
          totalLoadPalletSpace: 0,
          totalUnloadPallet: 0,
          totalUnloadPalletSpace: 0,
        });
      }
    }
  }, [filteredRowsData]);

  React.useEffect(() => {
    if (
      props.isHideRegistered === true &&
      group &&
      group !== 'All-Groups' &&
      rows &&
      rows.length > 0
    ) {
      const legsWithGroup = rows.filter((leg) => leg.productionGroup === group);
      const rowWithStatus = legsWithGroup?.map((obj) => ({
        ...obj,
        statusStr: getChipStatus(obj).toString(),
      }));

      const withoutRegisteredValues = rowWithStatus.filter(
        (i) =>
          i.statusStr === t('attributes.unregisteredStatus').toString() ||
          i.statusStr === t('attributes.partialRegisteredStatus').toString() ||
          i.statusStr === 'N/A',
      );
      setDataGrid(withoutRegisteredValues);
      //registeredCount is zero because we are hiding registered records
      setRegisteredCount(0);
      const totalLoadPallets =
        (_.sumBy(withoutRegisteredValues, 'loadCar') ?? 0) +
        (_.sumBy(withoutRegisteredValues, 'loadTrailer') ?? 0);
      const totoalLoadPalletSpace =
        (_.sumBy(withoutRegisteredValues, 'loadCarPPL') ?? 0) +
        (_.sumBy(withoutRegisteredValues, 'loadTrailerPPL') ?? 0);
      const totalUnloadPallets =
        (_.sumBy(withoutRegisteredValues, 'unloadCar') ?? 0) +
        (_.sumBy(withoutRegisteredValues, 'unloadTrailer') ?? 0);
      const totalUnloadPalletSpace =
        (_.sumBy(withoutRegisteredValues, 'unloadCarPPL') ?? 0) +
        (_.sumBy(withoutRegisteredValues, 'unloadTrailerPPL') ?? 0);
      setTotalObj({
        totalLoadPallet: totalLoadPallets,
        totalLoadPalletSpace: totoalLoadPalletSpace,
        totalUnloadPallet: totalUnloadPallets,
        totalUnloadPalletSpace: totalUnloadPalletSpace,
      });
    } else if (props.isHideRegistered === true && rows && rows.length > 0) {
      const rowWithStatus = rows.map((obj) => ({
        ...obj,
        statusStr: getChipStatus(obj).toString(),
      }));
      const withoutRegisteredValues = rowWithStatus.filter(
        (i) =>
          i.statusStr === t('attributes.unregisteredStatus').toString() ||
          i.statusStr === t('attributes.partialRegisteredStatus').toString() ||
          i.statusStr === 'N/A',
      );
      setDataGrid(withoutRegisteredValues);
      setRegisteredCount(0);
    } else if (group && group !== 'All-Groups') {
      const legsWithGroup = rows.filter((leg) => leg.productionGroup === group);
      const rowWithStatus = legsWithGroup.map((obj) => ({
        ...obj,
        statusStr: getChipStatus(obj).toString(),
      }));
      const registeredValues = rowWithStatus.filter(
        (i) => i.statusStr === t('attributes.registeredStatus').toString(),
      );
      setDataGrid(legsWithGroup);
      setRegisteredCount(registeredValues.length);
    } else {
      setDataGrid(rows);
      const rowWithStatus = rows.map((obj) => ({
        ...obj,
        statusStr: getChipStatus(obj).toString(),
      }));
      const registeredValues = rowWithStatus.filter(
        (i) => i.statusStr === t('attributes.registeredStatus').toString(),
      );
      setRegisteredCount(registeredValues.length);
    }

    if (rows && rows.length > 3) {
      setAutoHeights(false);
    } else {
      setAutoHeights(true);
    }
  }, [legs, props.isHideRegistered, group]);

  const handleCellChecked = React.useCallback(
    async (id: any, isChecked: boolean) => {
      const legId = id ? parseInt(id) : 0;
      if (legId !== 0) {
        await truckFillAndTimeMutation({
          variables: {
            truckFillAndTimeInput: {
              id: legId,
              terminalId: terminal.id,
              isNonArrivalMarked: isChecked,
            },
          },
        });
      }
    },
    [rows],
  );
  const handleSealBrokenChecked = React.useCallback(
    async (id: any, isChecked: boolean) => {
      const legId = id ? parseInt(id) : 0;
      if (legId !== 0) {
        await truckFillAndTimeMutation({
          variables: {
            truckFillAndTimeInput: {
              id: legId,
              terminalId: terminal.id,
              isSealBroken: isChecked,
            },
          },
        });
      }
    },
    [rows],
  );

  const handleCellEditCommit = React.useCallback(
    async ({ id, field, value }: GridCellEditCommitParams) => {
      const row = rows.find((x: { id: string }) => x.id == id);

      if (
        row == null ||
        (field === 'actualArrivalDate' && !value) ||
        (field === 'actualDepartureDate' && !value)
      ) {
        return;
      }

      await truckFillAndTimeMutation({
        variables: {
          truckFillAndTimeInput: {
            id: row.legId,
            terminalId: terminal.id,
            carGateNumber:
              field === 'carGateNumber' ? (value as string) : undefined,
            trailerGateNumber:
              field === 'trailerGateNumber' ? (value as string) : undefined,
            carRegistrationNumber:
              field === 'carRegistrationNumber' ? (value as string) : undefined,
            trailerRegistrationNumber:
              field === 'trailerRegistrationNumber'
                ? (value as string)
                : undefined,
            terminalNickname:
              field === 'terminalNickname' ? (value as string) : undefined,
            actualCarCapacity:
              field === 'actualCarCapacity'
                ? parseInt(value as string, 10)
                : undefined,
            actualTrailerCapacity:
              field === 'actualTrailerCapacity'
                ? parseInt(value as string, 10)
                : undefined,
            terminalNote:
              field === 'terminalNote' ? (value as string) : undefined,
            actualArrivalTime:
              field === 'actualArrivalTime' && typeof value === 'string'
                ? value.match(/\d{2}:\d{2}(:\d{2})?/)
                  ? (value as string)
                  : null
                : undefined,
            actualArrivalDate:
              field === 'actualArrivalDate' ? (value as string) : undefined,
            actualDepartureDate:
              field === 'actualDepartureDate' ? (value as string) : undefined,
            actualDepartureTime:
              field === 'actualDepartureTime' && typeof value === 'string'
                ? value.match(/\d{2}:\d{2}(:\d{2})?/)
                  ? (value as string)
                  : null
                : undefined,
            loadCar:
              field === 'carPallets' && row.load
                ? parseInt(value as string, 10)
                : undefined,
            loadTrailer:
              field === 'trailerPallets' && row.load
                ? parseInt(value as string, 10)
                : undefined,
            unloadCar:
              field === 'carPallets' && row.unload
                ? parseInt(value as string, 10)
                : undefined,
            unloadTrailer:
              field === 'trailerPallets' && row.unload
                ? parseInt(value as string, 10)
                : undefined,
            loadCarPPL:
              field === 'carPPL' && row.load
                ? parseInt(value as string, 10)
                : undefined,
            loadTrailerPPL:
              field === 'trailerPPL' && row.load
                ? parseInt(value as string, 10)
                : undefined,
            unloadCarPPL:
              field === 'carPPL' && row.unload
                ? parseInt(value as string, 10)
                : undefined,
            unloadTrailerPPL:
              field === 'trailerPPL' && row.unload
                ? parseInt(value as string, 10)
                : undefined,
            loadCages:
              field === 'cages' && row.load
                ? parseInt(value as string, 10)
                : undefined,
            unloadCages:
              field === 'cages' && row.unload
                ? parseInt(value as string, 10)
                : undefined,
            sealNumber: field === 'sealNumber' ? (value as string) : undefined,
            productionGroup:
              field === 'productionGroup' ? (value as string) : undefined,
            terminalResponsible:
              field === 'terminalResponsible' ? (value as string) : undefined,
          },
        },
      }).catch((err) => {
        if (field === 'sealNumber') {
          setErrorInfo({
            errorInField: true,
            errorMessage: t('validation.updateError'),
          });
        }
        if (field === 'actualDepartureTime') {
          setErrorInfo({
            errorInField: true,
            errorMessage: err.message,
          });
        }
        if (field === 'carPPL' || field === 'trailerPPL') {
          setErrorInfo({
            errorInField: true,
            errorMessage: t('validation.loadPPLIsAbove100'),
          });
          if (row.load && field === 'carPPL') {
            truckFillAndTimeMutation({
              variables: {
                truckFillAndTimeInput: {
                  id: row.legId,
                  terminalId: terminal.id,
                  loadCarPPL: row.loadCarPPL,
                },
              },
            });
          } else if (row.load && field === 'trailerPPL') {
            truckFillAndTimeMutation({
              variables: {
                truckFillAndTimeInput: {
                  id: row.legId,
                  terminalId: terminal.id,
                  loadTrailerPPL: row.loadTrailerPPL,
                },
              },
            });
          } else if (row.unload && field === 'carPPL') {
            truckFillAndTimeMutation({
              variables: {
                truckFillAndTimeInput: {
                  id: row.legId,
                  terminalId: terminal.id,
                  unloadCarPPL: row.unloadCarPPL,
                },
              },
            });
          } else if (row.unload && field === 'trailerPPL') {
            truckFillAndTimeMutation({
              variables: {
                truckFillAndTimeInput: {
                  id: row.legId,
                  terminalId: terminal.id,
                  unloadTrailerPPL: row.unloadTrailerPPL,
                },
              },
            });
          }
        }
      });
    },
    [rows],
  );

  const customComponents = {
    Toolbar: TftCustomGridToolbar,
    ColumnsPanel: CustomColumnsPanel,
  };

  const handleUploadClick = React.useCallback(
    (data: {
      routeName: string;
      legId: number;
      subcontractorName: string;
      existingFiles: DocumentFile[];
    }) => {
      setExtraData(data);
      setOpenUploadModal(true);
    },
    [],
  );

  const getBackColor = (percentage: number): string => {
    if (percentage) {
      if (percentage > 0 && percentage < 50) {
        return '#f07267';
      } else if (percentage >= 50 && percentage < 80) {
        return '#eda528';
      } else {
        return '#12de63';
      }
    } else {
      return '#ffffff';
    }
  };

  const getChipStatus = (row: any): string => {
    if (row.isNonArrivalMarked) {
      return t('attributes.cancelledStatus');
    }
    if (row.load && row.unload === false) {
      if (
        (((row.loadCar === 0 || row.loadCar != null) && row.loadCar !== '') ||
          ((row.loadTrailer === 0 || row.loadTrailer != null) &&
            row.loadTrailer !== '')) &&
        row.actualDepartureTime &&
        row.actualDepartureTime !== '' &&
        (row.loadCages === 0 || row.loadCages != null) &&
        row.loadCages !== ''
      ) {
        return t('attributes.registeredStatus');
      } else if (
        row.loadCar == null &&
        row.loadTrailer == null &&
        (row.actualDepartureTime == null || row.actualDepartureTime === '') &&
        row.loadCages == null
      ) {
        return t('attributes.unregisteredStatus');
      } else {
        return t('attributes.partialRegisteredStatus');
      }
    } else if (row.unload && row.load === false) {
      if (
        (((row.unloadCar === 0 || row.unloadCar != null) &&
          row.unloadCar !== '') ||
          ((row.unloadTrailer === 0 || row.unloadTrailer != null) &&
            row.unloadTrailer !== '')) &&
        row.actualArrivalTime &&
        row.actualArrivalTime !== '' &&
        (row.unloadCages === 0 || row.unloadCages != null) &&
        row.unloadCages !== ''
      ) {
        return t('attributes.registeredStatus');
      } else if (
        row.unloadCar == null &&
        row.unloadTrailer == null &&
        (row.actualArrivalTime == null || row.actualArrivalTime === '') &&
        row.unloadCages == null
      ) {
        return t('attributes.unregisteredStatus');
      } else {
        return t('attributes.partialRegisteredStatus');
      }
    } else if (row.load && row.unload) {
      if (
        (((row.loadCar === 0 || row.loadCar != null) && row.loadCar !== '') ||
          ((row.loadTrailer === 0 || row.loadTrailer != null) &&
            row.loadTrailer !== '')) &&
        (((row.unloadCar === 0 || row.unloadCar != null) &&
          row.unloadCar !== '') ||
          ((row.unloadTrailer === 0 || row.unloadTrailer != null) &&
            row.unloadTrailer !== '')) &&
        row.actualDepartureTime &&
        row.actualDepartureTime !== '' &&
        row.actualArrivalTime &&
        row.actualArrivalTime !== '' &&
        (row.loadCages === 0 || row.loadCages != null) &&
        row.loadCages !== '' &&
        (row.unloadCages === 0 || row.unloadCages != null) &&
        row.unloadCages !== ''
      ) {
        return t('attributes.registeredStatus');
      } else if (
        row.loadCar == null &&
        row.loadTrailer == null &&
        row.unloadCar == null &&
        row.unloadTrailer == null &&
        (row.actualDepartureTime == null || row.actualDepartureTime === '') &&
        (row.actualArrivalTime == null || row.actualArrivalTime === '') &&
        row.unloadCages == null &&
        row.loadCages == null
      ) {
        return t('attributes.unregisteredStatus');
      } else {
        return t('attributes.partialRegisteredStatus');
      }
    }
    return '';
  };

  const onChangeColumnVisibilityModel = (
    newModel: GridColumnVisibilityModel,
  ) => {
    if (newModel && !_.isEqual(newModel, gridColumnVisibilityModel)) {
      setGridColumnVisibilityModel(newModel);
    }
  };

  const updateTftConfiguration = () => {
    setConfigStoreLoading(true);
    const lookupObj = apiRef.current.state.columns.lookup;
    const lookup: any = {};
    for (const key in lookupObj) {
      if (lookupObj.hasOwnProperty(key)) {
        lookup[key] = { width: lookupObj[key].width };
      }
    }

    updateTftConfig({
      variables: {
        input: {
          configuration: {
            ...apiRef.current.state.columns,
            lookup,
          },
        },
      },
    }).then((res) => {
      if (res.data) setConfigStatus({ status: 'Success' });
      else setConfigStatus({ status: 'Fail' });
      refetchTftConfig();
      setConfigStoreLoading(false);
    });
  };

  const handleCarArrived = (params: GridRenderCellParams) => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const currentTime = format(new Date(), 'HH:mm');

    if (currentDate)
      updateActualArrivalDate(
        params.row.id,
        params.row.legId,
        currentDate,
        'actualDepartureDate',
        currentTime,
      );
  };

  const updateActualArrivalDate = (
    id: GridRowId,
    legId: number,
    date: string,
    field: string,
    actualArrivalTime?: string,
  ) => {
    if (date) {
      truckFillAndTimeMutation({
        variables: {
          truckFillAndTimeInput: {
            id: legId,
            terminalId: terminal.id,
            actualArrivalDate: date,
            actualArrivalTime:
              actualArrivalTime && typeof actualArrivalTime === 'string'
                ? actualArrivalTime.match(/\d{2}:\d{2}(:\d{2})?/)
                  ? (actualArrivalTime as string)
                  : null
                : undefined,
          },
        },
      }).then(() => {
        apiRef.current.setCellMode(id, field, 'view');
        const row = apiRef.current.getRow(id);
        if (row.actualDepartureDate !== 'null') {
          apiRef.current.setEditCellValue({
            field: 'actualDepartureDate',
            id,
            value: '',
          });
          apiRef.current.setCellMode(id, 'actualDepartureDate', 'view');
        }
      });
    }
  };

  const handleCarDeparted = (params: GridRenderCellParams) => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const currentTime = format(new Date(), 'HH:mm');

    if (currentDate)
      truckFillAndTimeMutation({
        variables: {
          truckFillAndTimeInput: {
            id: params.row.legId,
            terminalId: terminal.id,
            actualDepartureDate: currentDate,
            actualDepartureTime: currentTime,
          },
        },
      })
        .then(() => {
          apiRef.current.setCellMode(params.row.id, params.row.field, 'view');
        })
        .catch((err) => {
          setErrorInfo({
            errorInField: true,
            errorMessage: err.message,
          });
        });
  };

  const colArr: GridColumns = [
    {
      field: 'id',
      headerName: 'id',
      disableColumnMenu: true,
    },
    {
      field: 'viewLoadingList',
      headerName: 'View Loading List',
      minWidth: 100,
      type: 'actions',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const { legId, routeId2, routeId } = row;
        return (
          <LoadingListModal
            legId={legId}
            routeId={routeId2}
            routeName={routeId}
            httpClient={httpClient}
            loadingListError={() =>
              setErrorInfo({
                errorInField: true,
                errorMessage: t('validation.loadingListError'),
              })
            }
          />
        );
      },
    },

    {
      field: 'downloadLoadingList',
      headerName: 'Download Loading List',
      minWidth: 100,
      type: 'actions',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <Tooltip
            title={`${t('actions.viewItem', {
              item: t('resource.loadingList.capitalized'),
            })}`}
          >
            <IconButton
              onClick={() => {
                getLoadingList(parseInt(row.routeId2), row.routeId, httpClient);
              }}
            >
              <CloudDownloadIcon color="action" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: 'DownloadCMR',
      headerName: 'Download CMR',
      minWidth: 100,
      type: 'actions',
      sortable: false,

      renderCell: (params: GridCellParams) => {
        const { legId, routeId } = params.row;
        return (
          <DownloadCMR
            legId={legId}
            routeId={routeId}
            downloadCMRError={() => {
              setErrorInfo({
                errorInField: true,
                errorMessage: t('validation.noCMRFound'),
              });
            }}
          />
        );
      },
    },
    {
      headerName: 'Terminal Nickname',
      field: 'terminalNickname',
      minWidth: 100,
      editable: true,

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 140,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const chipText = getChipStatus(row);
        if (
          chipText.toString() === t('attributes.registeredStatus').toString()
        ) {
          return (
            <RegisteredChip
              label={chipText}
              variant="outlined"
              color="primary"
              key={row.id}
              icon={<DoneIcon />}
            />
          );
        } else if (
          chipText.toString() === t('attributes.unregisteredStatus').toString()
        ) {
          return (
            <UnregisteredChip
              label={chipText}
              variant="outlined"
              color="primary"
              key={row.id}
              icon={<InfoIcon />}
            />
          );
        } else if (
          chipText.toString() ===
          t('attributes.partialRegisteredStatus').toString()
        ) {
          return (
            <PartialRegisteredChip
              label={chipText}
              variant="outlined"
              color="primary"
              key={row.id}
              icon={<RefreshIcon />}
            />
          );
        } else if (
          chipText.toString() === t('attributes.cancelledStatus').toString()
        ) {
          return (
            <CancelledChip
              label={chipText}
              variant="outlined"
              color="primary"
              key={row.id}
              icon={<CancelledIcon />}
            />
          );
        } else {
          return null;
        }
      },
      valueGetter: (params: GridValueGetterParams) => getChipStatus(params.row),
    },
    {
      headerName: 'Non-arrival',
      field: 'nonArrival',
      minWidth: 100,
      type: 'boolean',
      align: 'center',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const {
          isProductionDayToday,
          isNonArrivalMarked,
          legId,
          transportationDate,
        } = row;
        if (
          isProductionDayToday === true ||
          transportationDate < format(new Date(), DATE_FORMAT)
        ) {
          return (
            <Checkbox
              style={{ padding: 0, color: '#ff0202b8' }}
              checked={isNonArrivalMarked}
              size="small"
              onChange={(_, checked) => {
                handleCellChecked(legId, checked);
              }}
            />
          );
        } else {
          return <></>;
        }
      },
      valueGetter: (params: GridValueGetterParams) =>
        params.row.isNonArrivalMarked,
    },
    {
      headerName: 'Route ID',
      field: 'routeId',
      minWidth: 100,
      pinnable: true,
      disableColumnMenu: true,
    },
    {
      headerName: 'Route Type',
      field: 'type',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.isCreatedFromTerminal) {
          return 'Terminal';
        }
        return row.type;
      },
    },
    {
      headerName: 'Subcontractor',
      field: 'subcontractorName',
      minWidth: 100,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const { subcontractorId } = row;
        if (!subcontractorId || subcontractorId == 0) {
          return null;
        }
        return (
          <Link
            variant="body2"
            href={`/terminal/cards/subcontractors/${subcontractorId}`}
            target={'_blank'}
            underline={'hover'}
            color={'inherit'}
          >
            {row.subcontractorName}
          </Link>
        );
      },
    },
    {
      headerName: 'Capacity',
      field: 'capacity',
      minWidth: 100,
      valueFormatter({ value }) {
        if (value == null) {
          return '';
        }
        return value;
      },
    },
    {
      headerName: 'Actual Car Capacity',
      field: 'actualCarCapacity',
      minWidth: 100,
      editable: true,
      type: 'number',
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Actual Trailer Capacity',
      field: 'actualTrailerCapacity',
      minWidth: 100,
      editable: true,
      type: 'number',
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Departure Terminal',
      field: 'departureTerminal',
      width: 100,
    },
    {
      headerName: 'Arriving Terminal',
      field: 'arrivingTerminal',
      width: 100,
    },
    {
      headerName: 'Departure City',
      field: 'departureCity',
      minWidth: 100,
    },
    {
      headerName: 'Arrival City',
      field: 'arrivalCity',
      minWidth: 100,
    },
    {
      headerName: 'Departure Country',
      filterOperators: filterOperatorsCountry,
      field: 'departureCountry',
      minWidth: 100,
      valueFormatter({ value }) {
        if (typeof value !== 'string') {
          return '';
        }
        return value.toUpperCase();
      },
    },
    {
      headerName: 'Arrival Country',
      filterOperators: filterOperatorsCountry,
      field: 'arrivalCountry',
      minWidth: 100,
      valueFormatter({ value }) {
        if (typeof value !== 'string') {
          return '';
        }
        return value.toUpperCase();
      },
    },
    {
      headerName: t('attributes.fromToToCountry'),
      field: 'fromToToCountry',
      minWidth: 100,
    },
    {
      headerName: 'Transportation Date',
      field: 'transportationDate',
      minWidth: 110,
      type: 'date',
    },
    {
      headerName: 'Arrival time',
      field: 'arrivalTime',
      minWidth: 100,
      valueFormatter(params) {
        const { value } = params;
        if (typeof value === 'string') {
          return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
        }
        return '';
      },
    },
    {
      headerName: 'Arrival Status',
      field: 'truckStatusBasedOnTime',
      minWidth: 110,
    },
    {
      headerName: 'Departure time',
      field: 'departureTime',
      minWidth: 100,
      valueFormatter(params) {
        const { value } = params;
        if (typeof value === 'string') {
          return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
        }
        return '';
      },
    },
    {
      headerName: 'Load type',
      field: 'loadType',
      minWidth: 100,
      pinnable: true,
      disableColumnMenu: true,
    },
    {
      headerName: 'Car Carrier ID',
      field: 'carCarrierId',
      minWidth: 110,
    },
    {
      headerName: 'Car Carrier QR',
      field: 'carCarrierIdQR',
      minWidth: 100,
      type: 'actions',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { carCarrierId, routeId, routeId2 } = params.row;
        return carCarrierId ? (
          <QRModal
            id={carCarrierId}
            routeName={routeId}
            routeId={routeId2}
            Icon={<ToysIcon />}
          />
        ) : null;
      },
    },
    {
      headerName: 'Trailer Carrier ID',
      field: 'trailerCarrierId',
      minWidth: 110,
    },
    {
      headerName: 'Trailer Carrier QR',
      field: 'trailerCarrierIdQR',
      minWidth: 100,
      type: 'actions',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { trailerCarrierId, routeId, routeId2 } = params.row;
        return trailerCarrierId ? (
          <QRModal
            id={trailerCarrierId}
            routeName={routeId}
            routeId={routeId2}
            Icon={<LocalShippingIcon />}
          />
        ) : null;
      },
    },
    {
      field: 'carArrived',
      headerName: 'Car arrived',
      type: 'actions',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleCarArrived(params)}
        >
          {'Arrived'}
        </Button>
      ),
    },
    {
      headerName: 'AAD',
      field: 'actualArrivalDate',
      minWidth: 140,
      type: 'date',
      editable: true,
      description: 'Actual Arrival Date',
      renderEditCell(params) {
        const { row, id } = params;
        return (
          <TftSelectDate
            onDateSelected={(selDate: any) => {
              if (selDate)
                updateActualArrivalDate(
                  id,
                  row.legId,
                  selDate,
                  'actualDepartureDate',
                );
            }}
          />
        );
      },
      renderCell(params) {
        const { row, id } = params;
        return (
          <SelectDate
            currentDate={row.actualArrivalDate}
            onDateSelected={(selDate: any) => {
              if (selDate)
                updateActualArrivalDate(
                  id,
                  row.legId,
                  selDate,
                  'actualDepartureDate',
                );
            }}
          />
        );
      },
    },
    {
      headerName: 'AAT',
      field: 'actualArrivalTime',
      minWidth: 100,
      type: 'dateTime',
      editable: true,
      description: 'Actual Arrival Time',
      renderEditCell(params) {
        return <TimePickerEditCell {...params} apiRef={apiRef} />;
      },
      renderCell(params) {
        const { value } = params;
        let time = '';
        if (typeof value === 'string') {
          if (value.match(/\d{2}:\d{2}:\d{2}/)) {
            time = format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
          } else if (value.match(/\d{2}:\d{2}/)) {
            time = format(parse(value, 'HH:mm', new Date()), 'HH:mm');
          }
        }
        return (
          <EditableCell
            highlightField={true}
            className={classes.editableCell}
            apiRef={apiRef}
            editable={true}
            {...params}
            value={time}
          />
        );
      },
    },
    {
      field: 'carDeparted',
      headerName: 'Car Departed',
      type: 'actions',
      width: 150,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleCarDeparted(params)}
            disabled={!(row.actualArrivalDate && row.actualArrivalTime)}
          >
            {'Departed'}
          </Button>
        );
      },
    },
    {
      headerName: 'ADD ',
      field: 'actualDepartureDate',
      minWidth: 140,
      type: 'date',
      editable: true,
      description: 'Actual Departure Date',
      renderEditCell(params) {
        const { row, id, field } = params;

        return (
          <TftSelectDate
            disabled={!(row.actualArrivalDate && row.actualArrivalTime)}
            onDateSelected={(selDate: any) => {
              if (selDate) {
                truckFillAndTimeMutation({
                  variables: {
                    truckFillAndTimeInput: {
                      id: row.legId,
                      terminalId: terminal.id,
                      actualDepartureDate: selDate,
                    },
                  },
                })
                  .then(() => {
                    apiRef.current.setCellMode(id, field, 'view');
                  })
                  .catch((err) => {
                    setErrorInfo({
                      errorInField: true,
                      errorMessage: err.message,
                    });
                  });
              }
            }}
          />
        );
      },
      renderCell(params) {
        const { row } = params;
        return (
          <SelectDate
            currentDate={row.actualDepartureDate}
            onDateSelected={(selDate: any) => {
              if (selDate) {
                truckFillAndTimeMutation({
                  variables: {
                    truckFillAndTimeInput: {
                      id: row.legId,
                      terminalId: terminal.id,
                      actualDepartureDate: selDate,
                    },
                  },
                }).catch((err) => {
                  setErrorInfo({
                    errorInField: true,
                    errorMessage: err.message,
                  });
                });
              }
            }}
            disabled={
              row.actualArrivalDate && row.actualArrivalTime ? false : true
            }
          />
        );
      },
    },
    {
      headerName: 'ADT',
      field: 'actualDepartureTime',
      minWidth: 100,
      type: 'dateTime',
      editable: true,
      description: 'Actual Departure Time',
      renderEditCell(params) {
        const { row } = params;
        return (
          <TimePickerEditCell
            {...params}
            apiRef={apiRef}
            disabled={!(row.actualArrivalDate && row.actualArrivalTime)}
          />
        );
      },
      renderCell(params) {
        const { value, row } = params;
        let time = '';
        if (typeof value === 'string') {
          if (value.match(/\d{2}:\d{2}:\d{2}/)) {
            time = format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
          } else if (value.match(/\d{2}:\d{2}/)) {
            time = format(parse(value, 'HH:mm', new Date()), 'HH:mm');
          }
        }

        return (
          <EditableCell
            highlightField={true}
            className={classes.editableCell}
            apiRef={apiRef}
            editable={row.actualArrivalDate && row.actualArrivalTime}
            {...params}
            value={time}
          />
        );
      },
    },
    {
      headerName: 'Terminal time info',
      field: 'timeObj',
      editable: true,
      filterable: false,
      type: 'actions',
      renderCell(params) {
        return (
          <TerminalTimeInfo
            timeObj={params.row.timeObj}
            routeId={params.row.routeId}
          />
        );
      },
    },
    {
      headerName: 'Car pallets',
      field: 'carPallets',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Trailer pallets',
      field: 'trailerPallets',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            className={classes.editableCell}
            editable={canEditCell(params.row, params.field)}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Pallets factor',
      field: 'palletsFactor',
      minWidth: 170,
      renderCell(params) {
        if (
          (!params.row.load && !params.row.unload) ||
          (params.row.load && params.row.unload)
        ) {
          return null;
        } else if (params.row.palletsFactor === '') {
          return (
            <Box width={'100%'}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  border: '1px solid #000',
                  color: '#ffffff',
                }}
              >
                <Typography align="left">
                  <label>{'0'}</label>
                </Typography>
              </div>
            </Box>
          );
        } else if (params.row.load) {
          //in this else even if we replace loadLoadFactor with palletsFactor it is OK
          return (
            <Box width={'100%'}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  border: '1px solid #000',
                }}
              >
                <div
                  style={{
                    width: `${
                      params.row.loadLoadFactor > 100
                        ? 100
                        : params.row.loadLoadFactor
                    }%`,
                    backgroundColor: `${getBackColor(
                      params.row.loadLoadFactor,
                    )}`,
                    paddingLeft: 4,
                  }}
                >
                  <Typography align="left">
                    <label>{params.row.loadSumPPI}</label>
                    <label>{' | '}</label>
                    <label>
                      <strong>{params.row.loadLoadFactor}%</strong>
                    </label>
                  </Typography>
                </div>
              </div>
            </Box>
          );
        } else {
          return (
            <Box width={'100%'}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  border: '1px solid #000',
                }}
              >
                <div
                  style={{
                    width: `${
                      params.row.unloadLoadFactor > 100
                        ? 100
                        : params.row.unloadLoadFactor
                    }%`,
                    backgroundColor: `${getBackColor(
                      params.row.unloadLoadFactor,
                    )}`,
                    paddingLeft: 4,
                  }}
                >
                  <Typography align="left">
                    <label>{params.row.unloadSumPPI}</label>
                    <label>{' | '}</label>
                    <label>
                      <strong>{params.row.unloadLoadFactor}%</strong>
                    </label>
                  </Typography>
                </div>
              </div>
            </Box>
          );
        }
      },
    },
    {
      headerName: 'Car PPL',
      field: 'carPPL',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Trailer PPL',
      field: 'trailerPPL',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            className={classes.editableCell}
            editable={canEditCell(params.row, params.field)}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'PPL factor',
      field: 'PPLFactor',
      minWidth: 170,
      renderCell(params) {
        if (
          (!params.row.load && !params.row.unload) ||
          (params.row.load && params.row.unload)
        ) {
          return null;
        } else if (params.row.PPLFactor === '') {
          return (
            <Box width={'100%'}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  border: '1px solid #000',
                  color: '#ffffff',
                }}
              >
                <Typography align="left">
                  <label>{'0'}</label>
                </Typography>
              </div>
            </Box>
          );
        } else if (params.row.load) {
          return (
            <Box width={'100%'}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  border: '1px solid #000',
                }}
              >
                <div
                  style={{
                    width: `${
                      params.row.PPLFactor > 100 ? 100 : params.row.PPLFactor
                    }%`,
                    backgroundColor: `${getBackColor(params.row.PPLFactor)}`,
                    paddingLeft: 4,
                  }}
                >
                  <Typography align="left">
                    <label>{params.row.loadPPLSum}</label>
                    <label>{' | '}</label>
                    <label>
                      <strong>{params.row.PPLFactor}%</strong>
                    </label>
                  </Typography>
                </div>
              </div>
            </Box>
          );
        } else {
          return (
            <Box width={'100%'}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  border: '1px solid #000',
                }}
              >
                <div
                  style={{
                    width: `${
                      params.row.PPLFactor > 100 ? 100 : params.row.PPLFactor
                    }%`,
                    backgroundColor: `${getBackColor(params.row.PPLFactor)}`,
                    paddingLeft: 4,
                  }}
                >
                  <Typography align="left">
                    <label>{params.row.unloadPPLSum}</label>
                    <label>{' | '}</label>
                    <label>
                      <strong>{params.row.PPLFactor}%</strong>
                    </label>
                  </Typography>
                </div>
              </div>
            </Box>
          );
        }
      },
    },
    {
      headerName: 'Cages',
      field: 'cages',
      minWidth: 110,
      editable: true,
      type: 'number',
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Note from terminal',
      field: 'terminalNote',
      minWidth: 100,
      editable: true,
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Leg note',
      field: 'note',
      minWidth: 100,
    },
    {
      headerName: 'Car Gate Number',
      field: 'carGateNumber',
      minWidth: 100,
      editable: true,
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            editable={true}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Trailer Gate Number',
      field: 'trailerGateNumber',
      minWidth: 100,
      editable: true,
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Car Registration Number',
      field: 'carRegistrationNumber',
      minWidth: 100,
      editable: true,
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Trailer Registration Number',
      field: 'trailerRegistrationNumber',
      minWidth: 100,
      editable: true,
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            editable={canEditCell(params.row, params.field)}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: ' Seal Number',
      field: 'sealNumber',
      minWidth: 110,
      editable: true,
      renderCell(params) {
        if (
          params.row.load === true ||
          (params.row.unload === true && params.row.isSealBroken === true)
        ) {
          return (
            <EditableCell
              highlightField={true}
              apiRef={apiRef}
              editable={canEditCell(params.row, params.field)}
              className={classes.editableCell}
              {...params}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Is Seal Broken',
      field: 'sealBroken',
      align: 'center',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const { unload, legId, isSealBroken, load } = row;
        if (unload === true && load === false) {
          return (
            <Checkbox
              style={{ padding: 0, color: '#fc3926' }}
              checked={isSealBroken}
              size="small"
              onChange={(_, checked) => handleSealBrokenChecked(legId, checked)}
            />
          );
        } else {
          return null;
        }
      },
      valueGetter: (params: GridValueGetterParams) => {
        const { row } = params;
        const { unload, isSealBroken, load } = row;
        if (unload === true && load === false) {
          return isSealBroken;
        }
      },
    },
    {
      headerName: 'Production Group',
      field: 'productionGroup',
      minWidth: 100,
      editable: true,
      valueParser: (value) => {
        return value ? value.toString().replaceAll(' ', '').toUpperCase() : '';
      },
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: 'Terminal Responsible',
      field: 'terminalResponsible',
      minWidth: 110,
      editable: true,
      renderCell(params) {
        return (
          <EditableCell
            highlightField={true}
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      field: 'uploadImage',
      headerName: 'Image file',
      minWidth: 75,
      type: 'actions',
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title={`${t('actions.uploadImage')}`}>
            <IconButton
              className={classes.iconStyle}
              onClick={() => {
                handleUploadClick({
                  routeName: params.row.routeId,
                  legId: params.row.legId,
                  subcontractorName: params.row.subcontractorName,
                  existingFiles: params.row.files,
                });
              }}
            >
              <UploadFileIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      headerName: 'Updated By',
      field: 'updatedBy',
      minWidth: 100,
    },
    {
      headerName: 'Updated at',
      field: 'updatedAt',
      type: 'dateTime',
      minWidth: 100,
      valueGetter({ value }) {
        return new Date(value as string);
      },
      valueFormatter({ value }) {
        return format(value as Date, 'HH:mm:ss - dd-MM-yyyy');
      },
    },
    {
      field: 'inboundFlag',
      headerName: 'Inbound',
      minWidth: 60,
      type: 'boolean',
      renderCell(params) {
        return <TrueOrFalseCellWithNoClearIcon {...params} />;
      },
    },
    {
      field: 'outboundFlag',
      headerName: 'Outbound',
      minWidth: 60,
      type: 'boolean',
      renderCell(params) {
        return <TrueOrFalseCellWithNoClearIcon {...params} />;
      },
    },
    {
      field: 'importFlag',
      headerName: 'Import',
      minWidth: 60,
      type: 'boolean',
      renderCell(params) {
        return <TrueOrFalseCellWithNoClearIcon {...params} />;
      },
    },
    {
      field: 'exportFlag',
      headerName: 'Export',
      minWidth: 60,
      type: 'boolean',
      renderCell(params) {
        return <TrueOrFalseCellWithNoClearIcon {...params} />;
      },
    },
    {
      headerName: 'rutekode',
      field: 'rutekode',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.rutekode == null) {
          return '';
        }
        return row.rutekode;
      },
    },
    {
      headerName: 'Togref',
      field: 'Togref',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.togref == null) {
          return '';
        }
        return row.togref;
      },
    },
    {
      headerName: 'Turnummer',
      field: 'turnummer',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.turnummer == null) {
          return '';
        }
        return row.turnummer;
      },
    },
    {
      headerName: 'bpx',
      field: 'bpx',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.bpx == null) {
          return '';
        }
        return row.bpx;
      },
    },
    {
      headerName: 'pri03',
      field: 'pri03',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.pri03 == null) {
          return '';
        }
        return row.pri03;
      },
    },
    {
      headerName: 'pri49',
      field: 'pri49',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.pri49 == null) {
          return '';
        }
        return row.pri49;
      },
    },
    {
      headerName: 'upri03',
      field: 'upri03',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.upri03 == null) {
          return '';
        }
        return row.upri03;
      },
    },
    {
      headerName: 'upri49',
      field: 'upri49',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.upri49 == null) {
          return '';
        }
        return row.upri49;
      },
    },
    {
      headerName: 'parti',
      field: 'parti',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.parti == null) {
          return '';
        }
        return row.parti;
      },
    },
    {
      headerName: 'brev',
      field: 'brev',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.brev == null) {
          return '';
        }
        return row.brev;
      },
    },
    {
      headerName: 'pru',
      field: 'pru',
      minWidth: 100,
      type: 'number',
      valueGetter({ row }) {
        if (row.pru == null) {
          return '';
        }
        return row.pru;
      },
    },
    {
      headerName: 'tom',
      field: 'tom',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.tom == null) {
          return '';
        }
        return row.tom;
      },
    },
    {
      headerName: t(`attributes.inneholdsbeskrivelse`),
      field: 'inneholdsbeskrivelse',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.inneholdsbeskrivelse == null) {
          return '';
        }
        return row.inneholdsbeskrivelse;
      },
    },
    {
      headerName: t(`attributes.trainNumber`),
      field: 'trainNumber',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.trainNumber == null) {
          return '';
        }
        return row.trainNumber;
      },
    },
    {
      headerName: t(`attributes.containerId`),
      field: 'containerId',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.containerId == null) {
          return '';
        }
        return row.containerId;
      },
    },
    {
      headerName: t(`attributes.transportType`),
      field: 'transportType',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.transportType == null) {
          return '';
        }
        return row.transportType;
      },
    },
  ];

  const reorderColumns = useCallback(() => {
    if (
      TftConfigData?.getTftConfig?.tftConfiguration?.all &&
      TftConfigData?.getTftConfig?.tftConfiguration?.all.length > 0 &&
      colArr &&
      colArr.length > 0
    ) {
      const savedOrder = TftConfigData?.getTftConfig?.tftConfiguration?.all;
      const lookUpObj = TftConfigData?.getTftConfig?.tftConfiguration?.lookup;
      return savedOrder
        .map((orderField: any) => {
          const column = colArr.find((col) => col.field === orderField);
          if (column) {
            const lookup = lookUpObj[orderField];
            if (lookup && lookup.width != null) {
              return { ...column, width: lookup.width };
            }
            return column;
          }
          return null;
        })
        .filter(Boolean); // Filter out undefined values
    }
    return colArr;
  }, [TftConfigData]);

  useEffect(() => {
    if (TftConfigData && TftConfigData?.getTftConfig?.tftConfiguration) {
      setGridColumnVisibilityModel(
        TftConfigData?.getTftConfig?.tftConfiguration.columnVisibilityModel,
      );
    }
    const reorderedColumns = reorderColumns();
    setColumnOrder((prevColumns) => {
      // Only update state if the new order is different
      if (JSON.stringify(prevColumns) !== JSON.stringify(reorderedColumns)) {
        return reorderedColumns;
      }
      return prevColumns;
    });
  }, [reorderColumns]);

  const onExportTFTExcelClicked = useCallback(() => {
    const delimiter = ';#';
    const csvString = apiRef.current.getDataAsCsv({ delimiter });
    const arrayOfArrayCsv = csvString.split('\n').map((row: string) => {
      return row.split(delimiter);
    });

    if (arrayOfArrayCsv.length > 1) {
      const allHeaders = arrayOfArrayCsv[0].map((str) => {
        return str.toLowerCase();
      });

      let priceColumnIndex = allHeaders.indexOf('agreed price');

      if (priceColumnIndex == -1)
        priceColumnIndex = allHeaders.indexOf('agreed price\r');

      if (priceColumnIndex >= 0) {
        for (let i = 1; i < arrayOfArrayCsv.length; i++) {
          if (
            arrayOfArrayCsv[i][priceColumnIndex] &&
            typeof arrayOfArrayCsv[i][priceColumnIndex] !== 'undefined' &&
            arrayOfArrayCsv[i][priceColumnIndex].trim().length > 3 &&
            arrayOfArrayCsv[i][priceColumnIndex].trim().includes(' ')
          )
            arrayOfArrayCsv[i][priceColumnIndex] = arrayOfArrayCsv[i][
              priceColumnIndex
            ].replace(/ /g, '');
        }
      }
    }

    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    XLSX.writeFile(wb, `${TABLE_NAMES.TruckFillAndTime}.xlsx`);
  }, [apiRef]);

  return (
    <>
      <Grid
        container
        style={{ marginTop: 5, marginBottom: 5 }}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={2} container justifyContent="flex-start">
          <Button
            variant="contained"
            color="primary"
            onClick={updateTftConfiguration}
            disabled={configStoreLoading}
          >
            {configStoreLoading
              ? `${t('states.loading')}...`
              : t('button.save', { item: 'Config.' })}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ fontWeight: 'bolder', display: 'inline-block' }}>
            {`LOAD  >> Total PAL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalLoadPallet}
            </Typography>
            {` | Total PPL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalLoadPalletSpace}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ fontWeight: 'bolder', display: 'inline-block' }}>
            {`UNLOAD >> Total PAL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalUnloadPallet}
            </Typography>
            {` | Total PPL: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalObj.totalUnloadPalletSpace}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ fontWeight: 'bolder', display: 'inline-block' }}>
            {` REG. Cars: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {registeredCount}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Box style={{ height: height }}>
        {dataGrid && columnOrder.length ? (
          <DataGridPro
            loading={loading && legs == null}
            error={error}
            apiRef={apiRef}
            filterModel={filterModel}
            disableColumnMenu
            components={customComponents}
            density={'compact'}
            onFilterModelChange={(model) => setFilterModel(model)}
            onCellEditCommit={handleCellEditCommit}
            disableSelectionOnClick
            pinnedColumns={{ left: ['routeId', 'loadType'] }}
            componentsProps={{
              columnMenu: {
                background: 'red',
                counter: rows.length,
              },
              toolbar: {
                onGetTFTExcelClicked,
                onExportTFTExcelClicked,
              },
            }}
            autoHeight={autoHeights}
            isCellEditable={(params: GridCellParams) => {
              const field = params.field;
              return canEditCell(params.row, field);
            }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            columns={columnOrder}
            rows={dataGrid}
            getRowClassName={(params) =>
              params.row.truckStatusBasedOnTime === 'LATE'
                ? classes.muiLateStatus
                : params.indexRelativeToCurrentPage % 2 === 0
                ? classes.muiEven
                : classes.muiOdd
            }
            onStateChange={(state) => {
              const visibleRows = state.filter.visibleRowsLookup;
              setFilteredRowsData(visibleRows);
            }}
            columnVisibilityModel={gridColumnVisibilityModel}
            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
          />
        ) : null}
        <div>
          {openUploadModal ? (
            <UploadFileModal
              open={openUploadModal}
              handleClose={() => {
                setOpenUploadModal(false);
              }}
              extraData={extraData}
              showLoading={showUploadLoading}
              onUploadStart={(legId, file) => {
                if (legId !== 0) {
                  setShowUploadLoading(true);

                  httpClient
                    .uploadAttachmentFile(file, legId, 'leg')
                    .then(() => {
                      setShowUploadLoading(false);
                      setOpenUploadModal(false);
                      //refresh TFT grid
                      refetchLatestLeg({
                        variables: {
                          legId: legId,
                          terminalId: terminal.id,
                        },
                      });
                    });
                }
              }}
              onDeleteClicked={(legId, fileId) => {
                setShowUploadLoading(true);

                deleteAttachmentFileMutation({
                  variables: {
                    input: {
                      entityId: legId,
                      entityType: 'leg',
                      fileId: fileId,
                    },
                  },
                }).then(() => {
                  //refresh TFT grid
                  refetchLatestLeg({
                    variables: {
                      legId: legId,
                      terminalId: terminal.id,
                    },
                  });
                  setShowUploadLoading(false);
                  setOpenUploadModal(false);
                });
              }}
            />
          ) : null}
        </div>
      </Box>
      <Snackbar
        open={errorInfo?.errorInField}
        key={'alert'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={5000}
        onClose={() => {
          setErrorInfo({ errorInField: false, errorMessage: '' });
        }}
      >
        <Alert
          onClose={() => {
            setErrorInfo({ errorInField: false, errorMessage: '' });
          }}
          severity="error"
        >
          {errorInfo?.errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={configStatus.status ? true : false}
        key={configStatus.status == 'Success' ? 'success' : 'alert'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={5000}
        onClose={() => {
          setConfigStatus({ status: '' });
        }}
      >
        <Alert
          onClose={() => {
            setConfigStatus({ status: '' });
          }}
          severity={configStatus.status == 'Success' ? 'success' : 'error'}
        >
          {configStatus.status == 'Success'
            ? t('attributes.saveConfigSuccessMessage')
            : t('attributes.failConfigMessage')}
        </Alert>
      </Snackbar>
    </>
  );
}

function canEditCell(
  leg: {
    capacity?: number;
    load?: boolean;
    unload?: boolean;
    isNonArrivalMarked?: boolean;
    isSealBroken?: boolean;
    isLoadingInCar?: boolean;
    isLoadingInTrailer?: boolean;
  },
  field: string,
): boolean {
  if (field == 'actualCarCapacity' && !leg.isLoadingInCar) return false;
  if (
    (field == 'actualTrailerCapacity' || field == 'trailerGateNumber') &&
    !leg.isLoadingInTrailer
  )
    return false;
  if (
    field == 'carPallets' ||
    field == 'trailerPallets' ||
    field == 'carPPL' ||
    field == 'trailerPPL'
  ) {
    if (leg.load && leg.unload) {
      return false;
    } else if (leg.load || leg.unload) {
      return true;
    } else {
      return false;
    }
  }
  if (leg.isNonArrivalMarked) {
    return false;
  }

  if (field === 'sealNumber') {
    if (leg.unload === true && leg.isSealBroken === true) return true;
    if (leg.load === true) return true;
    if (leg.load === false) return false;

    return true;
  }
  return true;
}

function getRecentPreviousLoadLeg(
  legs: NonNullable<ListIncomingRoutesProps['legs']>,
) {
  const thisLeg = legs[0];
  if (thisLeg.position != null) {
    let previousLegPosition = thisLeg.position === 0 ? 0 : thisLeg.position - 1;
    for (previousLegPosition; previousLegPosition >= 0; previousLegPosition--) {
      const newRouteLeg = thisLeg.route.legs.find(
        (leg) =>
          leg.position === previousLegPosition && leg.load && !leg.unload,
      );
      if (newRouteLeg) {
        return newRouteLeg;
      }
    }
  } else {
    thisLeg.route.legs.find((leg) => leg.position === 0);
  }
}

function buildRows(
  legs: ListIncomingRoutesProps['legs'],
  terminalLocation: TerminalLocation | undefined,
) {
  return (
    legs?.map((routeLeg) => {
      const { route } = routeLeg;
      const leg = routeLeg;
      const thisTerminalCountry = terminalLocation?.country
        ? COUNTRIES_MAP[
            terminalLocation?.country as keyof typeof COUNTRIES_MAP
          ]?.toUpperCase()
        : terminalLocation?.country?.toUpperCase();

      const {
        id,
        routeId,
        capacity,
        type,
        routeCreatedBy,
        routeDeletedAt,
        routeDeletedBy,
        routeLastUpdatedBy,
        routeUpdatedAt,
      } = route;
      const carActCapacity = leg.carDetails
        ? leg.carDetails.actualCapacity
        : leg.actualCapacity
        ? leg.actualCapacity
        : 0;
      const trailerActCapacity = leg.trailerDetails
        ? leg.trailerDetails.actualCapacity
        : 0;
      const routeCapacity =
        carActCapacity || trailerActCapacity
          ? (carActCapacity || 0) + (trailerActCapacity || 0)
          : capacity;
      const isProductionDayToday =
        leg.transportationDate &&
        leg.transportationDate === format(new Date(), DATE_FORMAT);

      const firstLegLocation =
        leg != null
          ? getRecentPreviousLoadLeg([leg])
          : route.legs.find((leg) => leg.position === 0);

      const lastLegLocation = route.legs.find(
        (leg) => leg.position === route.legs.length - 1,
      );
      const departureCity = firstLegLocation?.location?.city;
      const departureCountry =
        firstLegLocation?.location && firstLegLocation?.location.country
          ? COUNTRIES_MAP[
              firstLegLocation?.location.country as keyof typeof COUNTRIES_MAP
            ].toLocaleUpperCase()
          : '';
      const departureCountryInitial =
        firstLegLocation?.location && firstLegLocation?.location?.country
          ? firstLegLocation?.location.country
          : '';
      const arrivalCity = lastLegLocation?.location?.city;
      const arrivalCountry =
        lastLegLocation?.location && lastLegLocation?.location.country
          ? COUNTRIES_MAP[
              lastLegLocation?.location.country as keyof typeof COUNTRIES_MAP
            ].toLocaleUpperCase()
          : '';
      const arrivalCountryInitial =
        lastLegLocation?.location && lastLegLocation?.location?.country
          ? lastLegLocation?.location.country
          : '';
      const fromToToCountry = `${departureCountryInitial.toLocaleUpperCase()}-${arrivalCountryInitial.toLocaleUpperCase()}`;
      const departureTerminal =
        firstLegLocation?.location && firstLegLocation?.location.name
          ? firstLegLocation?.location.name
          : '';
      const arrivingTerminal =
        lastLegLocation?.location && lastLegLocation.location.name
          ? lastLegLocation.location.name
          : '';
      const subcontractorId = route.subcontractor.id;
      const subcontractorName = route.subcontractor.name;
      const loadSumPPI = (leg.loadCar || 0) + (leg.loadTrailer || 0);
      const unloadSumPPI = (leg.unloadCar || 0) + (leg.unloadTrailer || 0);

      const loadLoadFactor =
        routeCapacity == null || loadSumPPI === 0 || routeCapacity === 0
          ? ''
          : Math.floor((loadSumPPI / routeCapacity) * 100);

      const unloadLoadFactor =
        routeCapacity == null || unloadSumPPI === 0 || routeCapacity === 0
          ? ''
          : Math.floor((unloadSumPPI / routeCapacity) * 100);

      //for PPL
      const loadPPLSum = (leg.loadCarPPL || 0) + (leg.loadTrailerPPL || 0);
      const unloadPPLSum =
        (leg.unloadCarPPL || 0) + (leg.unloadTrailerPPL || 0);

      const loadPPLFactor =
        routeCapacity == null || loadPPLSum === 0 || routeCapacity === 0
          ? ''
          : Math.floor((loadPPLSum / routeCapacity) * 100);

      const unloadPPLFactor =
        routeCapacity == null || unloadPPLSum === 0 || routeCapacity === 0
          ? ''
          : Math.floor((unloadPPLSum / routeCapacity) * 100);

      return {
        id: `${id}-${leg.arrivalTime}`,
        legId: leg.id,
        routeId: routeId,
        routeId2: id,
        type,
        capacity,
        carGateNumber: leg.carDetails?.gateNumber
          ? leg.carDetails?.gateNumber
          : leg.gateNumber
          ? leg.gateNumber
          : '',
        trailerGateNumber: leg.trailerDetails?.gateNumber
          ? leg.trailerDetails?.gateNumber
          : '',
        trailerRegistrationNumber: leg.trailerDetails?.registrationNumber
          ? leg.trailerDetails?.registrationNumber
          : leg.trailerRegistrationNumber,
        carRegistrationNumber: leg.carDetails?.registrationNumber
          ? leg.carDetails?.registrationNumber
          : leg.carRegistrationNumber ?? '',
        terminalNickname: leg.terminalNickname,
        actualCapacity: leg.actualCapacity,
        load: leg.load,
        unload: leg.unload,
        departureTerminal,
        arrivingTerminal,
        departureCity,
        arrivalCity,
        departureCountry,
        arrivalCountry,
        fromToToCountry,
        arrivalTime: leg.arrivalTime,
        departureTime: timeOrNull(leg.departureTime),
        subcontractorId,
        subcontractorName,
        loadType:
          leg.load && leg.unload
            ? 'Load/Unload'
            : leg.load
            ? 'Load'
            : leg.unload
            ? 'Unload'
            : 'Checkpoint',
        carPallets:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadCar
            : leg.unload
            ? leg.unloadCar
            : undefined,
        trailerPallets:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadTrailer
            : leg.unload
            ? leg.unloadTrailer
            : undefined,
        palletsFactor:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? loadLoadFactor
            : leg.unload
            ? unloadLoadFactor
            : undefined,
        carPPL:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadCarPPL
            : leg.unload
            ? leg.unloadCarPPL
            : undefined,
        trailerPPL:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadTrailerPPL
            : leg.unload
            ? leg.unloadTrailerPPL
            : undefined,
        PPLFactor:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? loadPPLFactor
            : leg.unload
            ? unloadPPLFactor
            : undefined,
        cages:
          leg.load && leg.unload
            ? undefined
            : leg.load
            ? leg.loadCages
            : leg.unload
            ? leg.unloadCages
            : undefined,
        actualArrivalTime: leg.actualArrivalTime ?? '',
        actualArrivalDate: leg.actualArrivalDate ?? '',
        truckStatusBasedOnTime: leg.arrivalStatus ?? '',
        actualDepartureTime: leg.actualDepartureTime ?? '',
        actualDepartureDate: leg.actualDepartureDate ?? '',
        carCarrierId: route.carCarrierId ?? '',
        trailerCarrierId: route.trailerCarrierId ?? '',
        loadCar: leg.loadCar,
        loadCarPPL: leg.loadCarPPL,
        loadCages: leg.loadCages,
        loadTrailer: leg.loadTrailer,
        loadTrailerPPL: leg.loadTrailerPPL,
        loadSumPPI,
        loadLoadFactor,
        loadPPLSum,
        loadPPLFactor,
        position: leg.position,
        location: leg.location,
        route: leg.route,
        unloadCar: leg.unloadCar,
        unloadCarPPL: leg.unloadCarPPL,
        unloadCages: leg.unloadCages,
        unloadTrailer: leg.unloadTrailer,
        unloadTrailerPPL: leg.unloadTrailerPPL,
        unloadSumPPI,
        unloadLoadFactor,
        unloadPPLSum,
        unloadPPLFactor,
        note: leg.note,
        tourId: route?.tourRoute?.tourId,
        isCreatedFromTerminal: route?.isCreatedFromTerminal,
        isCreatedFromPlanned: route?.isCreatedFromPlanned,
        terminalNote: leg.terminalNote,
        updatedBy: leg.lastUpdatedBy,
        updatedAt: leg.updatedAt,
        sumOfPallets: 0,
        isContainDangerousGoods: false,
        productionDate: leg.productionDate,
        transportationDate: leg.transportationDate,
        isProductionDayToday: isProductionDayToday,
        isNonArrivalMarked: leg.isNonArrivalMarked ?? false,
        nonArrivalMarkedFrom: leg.nonArrivalMarkedFrom ?? '',
        sealNumber: leg.sealNumber ?? '',
        isSealBroken: leg.isSealBroken ?? false,
        files: leg.files,
        inboundFlag: leg.unload && thisTerminalCountry === departureCountry,
        outboundFlag: leg.load && thisTerminalCountry === arrivalCountry,
        importFlag: leg.unload && thisTerminalCountry !== departureCountry,
        exportFlag: leg.load && thisTerminalCountry !== arrivalCountry,
        productionGroup: leg.productionGroup,
        terminalResponsible: leg.terminalResponsible,
        arrivingPallets: 0,
        arrivingPalletSpace: 0,
        timeObj: leg.timeObj,
        routeCreatedBy,
        routeDeletedAt,
        routeDeletedBy,
        routeLastUpdatedBy,
        routeUpdatedAt,
        turnummer: leg.turnummer,
        togref: leg.togref,
        bpx: leg.bpx,
        pri03: leg.pri03,
        pri49: leg.pri49,
        upri03: leg.upri03,
        upri49: leg.upri49,
        parti: leg.parti,
        brev: leg.brev,
        pru: leg.pru,
        tom: leg.tom,
        rutekode: leg.rutekode,
        trainNumber: leg.trainNumber,
        inneholdsbeskrivelse: leg.inneholdsbeskrivelse,
        containerId: leg.containerId,
        transportType: leg.transportType,
        isLoadingInCar: leg.isLoadingInCar,
        isLoadingInTrailer: leg.isLoadingInTrailer,
        actualCarCapacity: carActCapacity ?? '',
        actualTrailerCapacity: trailerActCapacity ?? '',
      };
    }) || []
  );
}
