import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';

import Roboto from '../assets/Roboto-Regular.ttf';
import Logo from '../pdfImages/logo_bring.png';
import IcInfo from '../pdfImages/icon_info.png';
import IcImportant from '../pdfImages/icon_important.png';
import { useTranslation } from 'react-i18next';
import { PdfReportBoxType } from './PdfReportFromTerminal';
import { InfoType, InternalTerminalReportInfo } from '../../generated/graphql';
import { removeSecondsFromTimeString } from '../../lib/time';
import { ListIncomingRoutesLegs } from '../../views/TrafficTFT';
import RobotoBold from '../assets/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  format: 'truetype',
  fontWeight: 'bold',
  src: RobotoBold,
});
Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: Roboto,
});

const classes = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
    width: '100%',
    fontFamily: 'Roboto',
  },
  fullRow: {
    width: '100%',
    flexDirection: 'row',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'grey',
    marginBottom: 2,
  },
  section: {
    padding: '6pt 2pt',
  },
  routeStyles: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  headerTitle: {
    width: '82%',
  },
  headerText: {
    fontWeight: 400,
    fontSize: 12,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: 8,
  },
  headerLogo: {
    width: '18%',
    justifyContent: 'flex-end',
  },
  rowTitle: {
    fontWeight: 500,
    fontSize: 11,
    marginBottom: 3,
    marginTop: 10,
  },
  //for every column 2 styles - header & value
  routeHeaderFields: {
    fontWeight: 600,
    fontSize: 10,
    width: '22%',
    backgroundColor: '#d1d1d1',
    padding: '3pt',
  },

  headerColRouteId: {
    fontWeight: 600,
    fontSize: 10,
    width: '20%',
    backgroundColor: '#d1d1d1',
    padding: '3pt',
    borderBottom: '1px solid #000000',
    borderLeft: '1px solid #000000',
    borderTop: '1px solid #000000',
  },
  valueColRouteId: {
    fontWeight: 300,
    fontSize: 10,
    width: '20%',
    padding: '3pt',
    borderBottom: '1px solid #000000',
    borderLeft: '1px solid #000000',
  },

  headerColSubcontractor: {
    fontWeight: 600,
    fontSize: 10,
    width: '25%',
    backgroundColor: '#d1d1d1',
    padding: '3pt',
    borderBottom: '1px solid #000000',
    borderLeft: '1px solid #000000',
    borderTop: '1px solid #000000',
  },
  valueColSubcontractor: {
    fontWeight: 300,
    fontSize: 10,
    width: '25%',
    padding: '3pt',
    borderBottom: '1px solid #000000',
    borderLeft: '1px solid #000000',
  },

  headerColLNote: {
    fontWeight: 600,
    fontSize: 10,
    width: '32%',
    backgroundColor: '#d1d1d1',
    padding: '3pt',
    border: '1px solid #000000',
  },
  valueColLNote: {
    fontWeight: 300,
    fontSize: 10,
    width: '32%',
    padding: '3pt',
    borderBottom: '1px solid #000000',
    borderLeft: '1px solid #000000',
    borderRight: '1px solid #000000',
  },
  headerCenterWrapper: {
    width: '7%',
    backgroundColor: '#d1d1d1',
    padding: '3pt',
    borderBottom: '1px solid #000000',
    borderLeft: '1px solid #000000',
    borderTop: '1px solid #000000',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerColCapTime: {
    fontWeight: 600,
    fontSize: 10,
  },
  valueCenterWrapper: {
    width: '7%',
    padding: '3pt',
    borderBottom: '1px solid #000000',
    borderLeft: '1px solid #000000',
    flexDirection: 'column',
    alignItems: 'center',
  },
  valueColCapTime: {
    fontWeight: 300,
    fontSize: 10,
  },
  //for box
  boxMainRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  boxRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #000000',
    marginTop: 8,
    marginRight: 8,
    marginBottom: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  boxNameText: {
    fontSize: 10,
  },
  boxValueText: {
    fontSize: 10,
    marginLeft: 7,
  },
  boxNameWrapper: {
    padding: '2px 4px 2px 4px',
    backgroundColor: '#d1d1d1',
  },
  boxValueWrapper: {
    width: 34,
    backgroundColor: '#ffffff',
  },
  //info
  infoRowWrapper: {
    minHeight: 26,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 8,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  infoIconWrapper: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  infoIconImg: {
    height: 14,
    width: 10,
  },
  infoSeprator: {
    marginHorizontal: 8,
    height: '100%',
    width: 1,
    backgroundColor: 'grey',
  },
  infoText: {
    fontSize: 10,
  },
});

export type TftPdfReportProps = {
  selectedDateRange: string;
  terminalName: string;
  reportValueBoxData: PdfReportBoxType[];
  reportInfoData: Pick<
    InternalTerminalReportInfo,
    'id' | 'info' | 'infoType' | 'dateForAddInfo'
  >[];
  routeLegs: ListIncomingRoutesLegs[];
};

//Create Document for report

export const TftPdfReport = (props: TftPdfReportProps) => {
  const {
    reportValueBoxData,
    reportInfoData,
    routeLegs,
    selectedDateRange,
    terminalName,
  } = props;
  const { t } = useTranslation();
  const getProperTimeString = (timeValStr: string): string => {
    if (timeValStr !== '') {
      return timeValStr.substring(0, 2) + ':' + timeValStr.substring(2);
    } else {
      return '';
    }
  };
  const getTimeStringFromNumber = (numberValue: number): string => {
    const numStr = numberValue.toString();

    if (numStr.length === 4) {
      return getProperTimeString(numStr);
    } else if (numStr.length === 3) {
      return getProperTimeString('0' + numStr);
    } else if (numStr.length === 2) {
      return '00:' + numStr;
    } else if (numStr.length === 1) {
      return '00:0' + numStr;
    } else {
      return '';
    }
  };
  const valueBoxData = reportValueBoxData.map((boxData) => {
    if (boxData.isTimeBox) {
      return {
        ...boxData,
        boxValue: getTimeStringFromNumber(+boxData.boxValue),
      };
    } else {
      return boxData;
    }
  });

  return (
    <Document
      title={`Report From Traffic for - ${terminalName}`}
      author="Posten - Nexus linehaul"
      subject={`Report From Traffic for - ${terminalName}`}
    >
      <Page size="A4" style={classes.page} wrap={false}>
        <View style={classes.fullRow}>
          <View style={classes.headerTitle}>
            <Text style={classes.headerText}>
              {t('resource.reportFor.capitalized')} | {terminalName}
            </Text>
          </View>
          <View style={classes.headerLogo}>
            <Image
              style={{
                width: '90%',
                height: 'auto',
              }}
              src={Logo}
            />
          </View>
        </View>
        <View style={classes.divider}></View>
        <View style={classes.fullRow}>
          <View style={classes.headerTitle}>
            <Text style={classes.headerText}>
              {`Date range |${' '} ${selectedDateRange}`}
            </Text>
          </View>
        </View>

        {routeLegs && routeLegs.length === 0 && (
          <View style={classes.section}>
            <Text style={classes.rowTitle}>
              {t('resource.reportRouteData')}
            </Text>
          </View>
        )}

        {routeLegs && routeLegs.length > 0 && (
          <View style={classes.section}>
            <Text style={classes.rowTitle}>{`Routes`}</Text>
            <View style={{ flexDirection: 'column' }}>
              <View style={classes.routeStyles}>
                <Text style={classes.headerColRouteId}>ROUTE ID</Text>
                <Text style={classes.headerColSubcontractor}>
                  SUBCONTRACTOR
                </Text>
                <View style={classes.headerCenterWrapper}>
                  <Text style={classes.headerColCapTime}>CAP.</Text>
                </View>
                <View style={classes.headerCenterWrapper}>
                  <Text style={classes.headerColCapTime}>A.T.</Text>
                </View>
                <Text style={classes.headerColLNote}>LICENSE PLATE & NOTE</Text>
              </View>
              {/* loop inbound data */}
              {routeLegs.map((routeLeg, index) => {
                return (
                  <View key={index} style={classes.fullRow}>
                    <Text style={classes.valueColRouteId}>
                      {routeLeg.route.routeId || ''}
                    </Text>
                    <Text style={classes.valueColSubcontractor}>
                      {routeLeg.route.subcontractor
                        ? routeLeg.route.subcontractor.name
                        : ''}
                    </Text>
                    <View style={classes.valueCenterWrapper}>
                      <Text style={classes.valueColCapTime}>
                        {routeLeg.route.capacity || ''}
                      </Text>
                    </View>
                    <View style={classes.valueCenterWrapper}>
                      <Text style={classes.valueColCapTime}>
                        {removeSecondsFromTimeString(routeLeg.arrivalTime) ??
                          ''}
                      </Text>
                    </View>
                    <Text style={classes.valueColLNote}>
                      {`${
                        routeLeg.carDetails
                          ? routeLeg.carDetails.registrationNumber ?? ''
                          : routeLeg.route.licensePlate ?? ''
                      }  ${
                        routeLeg.note ? routeLeg.note : routeLeg.route.note
                      }` || ''}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        )}

        {/* valueboxes */}
        <View style={classes.section}>
          <View style={classes.boxMainRowContainer}>
            {valueBoxData.map((box, index) => {
              return (
                <View key={index} style={classes.boxRowWrapper}>
                  <View style={classes.boxNameWrapper}>
                    <Text style={classes.boxNameText}>{box.boxNameText}</Text>
                  </View>
                  <View style={classes.boxValueWrapper}>
                    <Text style={classes.boxValueText}>{box.boxValue}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
        {/* info */}
        <View style={classes.section}>
          {reportInfoData.map((info, index) => {
            return (
              <View key={index} style={classes.infoRowWrapper}>
                <View style={classes.infoIconWrapper}>
                  {info.infoType === InfoType.Info && (
                    <Image style={classes.infoIconImg} src={IcInfo} />
                  )}
                  {info.infoType === InfoType.Important && (
                    <Image style={classes.infoIconImg} src={IcImportant} />
                  )}
                </View>
                <View style={classes.infoSeprator} />
                <Text style={classes.infoText}>{info.info}</Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};
