import { makeStyles } from '@material-ui/styles';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box } from '@mui/system';
import {
  DataGridPro,
  GridColDef,
  GridColumnsPanel,
  GridColumnVisibilityModel,
  GridDensity,
  GridDensityTypes,
  GridLinkOperator,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { useUserConfiguration } from '../../providers/UserConfigurationProvider';
import _, { isEqual, pick } from 'lodash';
import { TABLE_NAMES } from '../../lib/constants';
import { Button, Checkbox, Tooltip, Typography } from '@mui/material';
import { configuration } from '../../configuration';
import { Compress, Expand } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      lineHeight: 1,
      textAlign: 'left',
      flexDirection: 'column-reverse !important',
      whiteSpace: 'pre-wrap',
      justifyContent: 'flex-start !important',
      alignContent: 'flex-start',
      flexWrap: 'wrap',
      height: 50,
    },
    '& .MuiDataGrid-columnSeparator': {
      marginTop: 34,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      width: '100%',
      textOverflow: 'initial',
    },
  },
}));

interface UserConfiguredDataGridProProps {
  tableName: string;
  onGetTFTExcelClicked?: () => void;
  onNewLegAddClick?: () => void;
  onExportLoadingList?: () => void;
  showRowCountOnHeader?: number;
}

export function UserConfiguredDataGridPro(
  props: UserConfiguredDataGridProProps & ComponentProps<typeof DataGridPro>,
) {
  const apiRef = props.apiRef ?? useGridApiRef();
  const {
    getFilterModel,
    getSortModel,
    getGridDensity,
    getColumnsConfiguration,
    attachConfiguration,
    updateColumnConfiguration,
    updateColumnVisibilityConfiguration,
    updateBulkVisibilityConfiguration,
    updateFilterConfiguration,
    updateGridDensity,
    updateSortConfiguration,
  } = useUserConfiguration();
  const [densitySet, setDensitySet] = useState(false);
  const classes = useStyles();
  const {
    showRowCountOnHeader,
    tableName,
    columns,
    components,
    onGetTFTExcelClicked = null,
    onNewLegAddClick = null,
    onExportLoadingList = null,
  } = props;
  const columnsConfiguration = getColumnsConfiguration(tableName);

  const userConfiguredColumns = useMemo(() => {
    return attachConfiguration(columns, columnsConfiguration);
  }, [columnsConfiguration, tableName]);

  const setVisibilityModelDefault = (): GridColumnVisibilityModel => {
    //set default column visibility based on table/grid
    if (tableName) {
      switch (tableName) {
        case TABLE_NAMES.TruckFillAndTime:
          return {
            id: false,
            newArrivalTime: false,
            arrivalCountry: false,
            departureCountry: false,
            inboundFlag: false,
            outboundFlag: false,
            importFlag: false,
            exportFlag: false,
            carCarrierId: false,
            carCarrierIdQR: false,
            trailerCarrierId: false,
            trailerCarrierIdQR: false,
          };
        case TABLE_NAMES.FixedTrafficOverview:
          return {
            id: false,
          };
        case TABLE_NAMES.ShipmentList:
          return {
            id: false,
          };
        case TABLE_NAMES.TFTReadOnly:
          return {
            id: false,
          };
        case TABLE_NAMES.TourRouteTemplates:
          return {
            id: false,
            endExceptionDate: false,
            startExceptionDate: false,
            startDate: false,
            endDate: false,
            dispatcher: false,
            fuel: false,
            lastUpdated: false,
            lastUpdatedBy: false,
            createdBy: false,
            days: false,
            note: false,
            externalNote: false,
          };
        case TABLE_NAMES.TourTemplates:
          return {
            createdBy: false,
            lastUpdatedBy: false,
            lastUpdated: false,
            fuel: false,
            dispatcher: false,
            endExceptionDate: false,
            startExceptionDate: false,
          };
        case TABLE_NAMES.OtherTours:
          return {
            createdBy: false,
            lastUpdatedBy: false,
            lastUpdated: false,
          };
        case TABLE_NAMES.ViewSporadicRouteTemplates:
          return {
            id: false,
          };
        case TABLE_NAMES.Checkpoints:
        case TABLE_NAMES.Customers:
        case TABLE_NAMES.Terminals:
        case TABLE_NAMES.Subcontractors:
          return {
            id: false,
          };
        case TABLE_NAMES.RemainingGoodsReport:
          return {
            id: false,
            createdBy: false,
            lastUpdatedBy: false,
          };
        case TABLE_NAMES.RouteDeviations:
          return {
            arrivalCity: false,
            departureCity: false,
            routeLicensePlate: false,
            routeCapacity: false,
          };
        case TABLE_NAMES.FixedRoutes:
        case TABLE_NAMES.SporadicRoutes:
          return {
            id: false,
            routeId: false,
            tour: false,
            fuel: false,
            driverName: false,
            driverPhoneNumber: false,
            licensePlate: false,
          };
        case TABLE_NAMES.RouteLegInReport:
          return {
            id: false,
          };
        case TABLE_NAMES.PeakPlanning:
        case TABLE_NAMES.PeakPlanningDebit:
          return {
            id: !configuration.production,
          };
        case TABLE_NAMES.ShipmentGrid:
          return {
            id: false,
          };
        case TABLE_NAMES.EditableLegLevelGrid:
          return {
            legsLength: false,
          };
        case TABLE_NAMES.MatchingShipment:
          return {
            id: false,
          };
        default:
          return {};
      }
    } else return {};
  };

  const [gridColumnVisibilityModel, setGridColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(setVisibilityModelDefault());
  const [currentGridDensity, setCurrentGridDensity] = useState<
    GridDensity | undefined
  >(getGridDensity(tableName));

  //JSON
  const setVisibilityModelFromConfig = (config: {
    [column: string]: Omit<GridColDef<any, any, any>, 'field'>;
  }) => {
    //set default column visibility
    if (Object.entries(config).length > 0) {
      const visibilityObj: GridColumnVisibilityModel = {};
      Object.entries(config).forEach((obj) => {
        if (obj[1] && obj[1].hasOwnProperty('hide')) {
          visibilityObj[obj[0]] =
            obj[1] && obj[1].hide !== undefined && obj[1].hide !== null
              ? !obj[1].hide
              : false;
        }
      });

      setGridColumnVisibilityModel({
        ...gridColumnVisibilityModel,
        ...visibilityObj,
      });
    }
  };

  useEffect(() => {
    if (columnsConfiguration) {
      setVisibilityModelFromConfig(columnsConfiguration);
    }
  }, []);

  useEffect(() => {
    if (apiRef?.current?.setDensity != null) {
      apiRef.current.setDensity(currentGridDensity ?? GridDensityTypes.Compact);
      setDensitySet(true);
    }
  }, [apiRef]);

  const filterModel = getFilterModel(tableName);
  const sortModel = getSortModel(tableName);

  const customComponents = {
    ...components,
    Toolbar: components?.Toolbar ?? CustomGridToolbar,
    ColumnsPanel: CustomColumnsPanel,
  };

  const onChangeColumnVisibilityModel = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      if (newModel && !_.isEqual(newModel, gridColumnVisibilityModel)) {
        const changedField = Object.keys(newModel).filter(
          (key) => newModel[key] !== gridColumnVisibilityModel[key],
        );
        if (
          changedField &&
          changedField.length === 1 &&
          tableName !== TABLE_NAMES.TruckFillAndTime
        ) {
          const isVisible = newModel[changedField[0]] as boolean;

          if (tableName != TABLE_NAMES.TFTReadOnly)
            updateColumnVisibilityConfiguration(tableName, changedField[0], {
              hide: !isVisible,
            });
        } else if (
          changedField &&
          changedField.length > 1 &&
          tableName !== TABLE_NAMES.TruckFillAndTime
        ) {
          //hideAll OR showAll
          const newVisibility = Object.keys(newModel).filter(
            (key) => newModel[key] === true,
          );

          updateBulkVisibilityConfiguration(
            tableName,
            changedField,
            newVisibility.length > 0,
          );
        }

        setGridColumnVisibilityModel(newModel);
      }
    },
    [gridColumnVisibilityModel],
  );

  const onExportClicked = useCallback(() => {
    const delimiter = ';#';
    const csvString = apiRef.current.getDataAsCsv({ delimiter });
    const arrayOfArrayCsv = csvString.split('\n').map((row: string) => {
      return row.split(delimiter);
    });

    if (
      tableName === TABLE_NAMES.SporadicRoutes &&
      arrayOfArrayCsv.length > 1
    ) {
      const allHeaders = arrayOfArrayCsv[0].map((str) => {
        return str.toLowerCase();
      });

      let priceColumnIndex = allHeaders.indexOf('agreed price');

      if (priceColumnIndex == -1)
        priceColumnIndex = allHeaders.indexOf('agreed price\r');

      if (priceColumnIndex >= 0) {
        for (let i = 1; i < arrayOfArrayCsv.length; i++) {
          if (
            arrayOfArrayCsv[i][priceColumnIndex] &&
            typeof arrayOfArrayCsv[i][priceColumnIndex] !== 'undefined' &&
            arrayOfArrayCsv[i][priceColumnIndex].trim().length > 3 &&
            arrayOfArrayCsv[i][priceColumnIndex].trim().includes(' ')
          )
            arrayOfArrayCsv[i][priceColumnIndex] = arrayOfArrayCsv[i][
              priceColumnIndex
            ].replace(/ /g, '');
        }
      }
    }

    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    XLSX.writeFile(wb, `${tableName}.xlsx`);
  }, [apiRef]);

  const onAddNewLeg = () => {
    if (onNewLegAddClick) onNewLegAddClick();
  };
  const [pageSize, setPageSize] = useState<number>(200);
  return (
    <DataGridPro
      className={classes.root}
      disableColumnReorder
      {...props}
      components={customComponents}
      componentsProps={{
        toolbar: {
          onExportClicked,
          onGetTFTExcelClicked,
          onExportLoadingList,
          tableName,
          onAddNewLeg,
          showRowCountOnHeader,
        },
      }}
      hideFooter={true}
      headerHeight={props.headerHeight || 100}
      apiRef={apiRef}
      disableColumnMenu={false}
      columns={userConfiguredColumns}
      filterModel={
        props.filterModel && props.filterModel.items.length > 0
          ? props.filterModel
          : filterModel
      }
      sortModel={props.sortModel ?? sortModel}
      pinnedColumns={props?.pinnedColumns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[50, 100, 200]}
      pagination={props.pagination ?? false}
      onStateChange={(state) => {
        if (densitySet && state.density.value !== currentGridDensity) {
          setCurrentGridDensity(state.density.value);
          if (tableName !== TABLE_NAMES.TruckFillAndTime)
            updateGridDensity(tableName, state.density.value);
        }
        if (
          (tableName === TABLE_NAMES.RemainingGoods && props?.onStateChange) ||
          (tableName === TABLE_NAMES.TruckFillAndTime &&
            props?.onStateChange) ||
          (tableName === TABLE_NAMES.TFTReadOnly && props?.onStateChange) ||
          (tableName === TABLE_NAMES.FixedRoutes && props?.onStateChange) ||
          (tableName === TABLE_NAMES.RemainingGoodsReport &&
            props?.onStateChange)
        ) {
          return props.onStateChange(state, {}, {});
        }
      }}
      onFilterModelChange={(...args) => {
        const [model] = args;
        if (tableName !== TABLE_NAMES.TFTReadOnly) {
          updateFilterConfiguration(tableName, model);
        }

        if (props.onFilterModelChange != null) {
          return props.onFilterModelChange(...args);
        }
        model.quickFilterLogicOperator = GridLinkOperator.And;
      }}
      onSortModelChange={(...args) => {
        const [model] = args;
        const interestingProperties = ['columnField', 'operatorValue', 'value'];
        if (tableName !== TABLE_NAMES.TFTReadOnly) {
          if (
            !isEqual(
              pick(filterModel, interestingProperties),
              pick(model, interestingProperties),
            )
          ) {
            updateSortConfiguration(tableName, model);
          }
        }

        if (props.onSortModelChange != null) {
          return props.onSortModelChange(...args);
        }
      }}
      onColumnWidthChange={(...args) => {
        const [params] = args;
        const colDef = params.colDef;
        const field = colDef.field;
        if (
          tableName !== TABLE_NAMES.TruckFillAndTime &&
          tableName !== TABLE_NAMES.TFTReadOnly
        )
          updateColumnConfiguration(tableName, field, {
            width: params.width,
          });

        if (props.onColumnWidthChange != null) {
          return props.onColumnWidthChange(...args);
        }
      }}
      columnVisibilityModel={
        props.columnVisibilityModel ?? gridColumnVisibilityModel
      }
      onColumnVisibilityModelChange={(...args) => {
        if (props.onColumnVisibilityModelChange != null) {
          return props.onColumnVisibilityModelChange(...args);
        } else {
          onChangeColumnVisibilityModel(args[0]);
        }
      }}
    />
  );
}

const columnsPanelUseStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minWidth: '300px',
    maxHeight: '450px',
    flexDirection: 'row',
    width: '100%',
    '& .MuiGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

export function CustomColumnsPanel() {
  const classes = columnsPanelUseStyles();
  return (
    <Box className={classes.root}>
      <GridColumnsPanel />
    </Box>
  );
}

export interface CustomGridToolbarProps {
  onExportClicked: () => void;
  onGetTFTExcelClicked: () => void;
  onExportLoadingList: () => void;
  tableName: string;
  onAddNewLeg: () => void;
  showRowCountOnHeader?: number;
  toggleRowHeight: {
    isChecked: boolean;
    onToggleRowHeight: () => void;
  };
}

export function CustomGridToolbar(props: CustomGridToolbarProps) {
  const {
    onExportClicked,
    onGetTFTExcelClicked,
    onExportLoadingList,
    tableName,
    onAddNewLeg,
    showRowCountOnHeader,
    toggleRowHeight,
  } = props;
  const { t } = useTranslation();

  return (
    <GridToolbarContainer>
      {tableName === TABLE_NAMES.EditableLegLevelGrid ? (
        <Button
          size="small"
          variant="contained"
          onClick={onAddNewLeg}
          style={{
            marginRight: 10,
            backgroundColor: '#68bc46',
            color: '#000',
          }}
        >
          {'Add Leg'}
        </Button>
      ) : null}

      <GridToolbarColumnsButton
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarFilterButton
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarDensitySelector
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <Button
        style={{ color: '#69bc46' }}
        startIcon={<SaveAltIcon />}
        color="primary"
        size="small"
        onClick={
          tableName === TABLE_NAMES.TruckFillAndTime ||
          tableName === TABLE_NAMES.TFTReadOnly
            ? onGetTFTExcelClicked
            : onExportClicked
        }
      >
        {tableName === TABLE_NAMES.TruckFillAndTime ||
        tableName === TABLE_NAMES.TFTReadOnly
          ? `${t('actions.get', { item: 'Excel' })}`
          : `${t('actions.export', { item: 'Excel' })} `}
      </Button>

      {tableName === TABLE_NAMES.TFTReadOnly ? (
        <Button
          style={{ color: '#69bc46' }}
          startIcon={<SaveAltIcon />}
          color="primary"
          size="small"
          onClick={onExportClicked}
        >
          {`${t('actions.export', { item: 'Excel' })} `}
        </Button>
      ) : null}
      {tableName === TABLE_NAMES.TruckFillAndTime ? (
        <Tooltip title={`${t('attributes.sortingInfo')}`}>
          <Typography style={{ marginLeft: 7, fontSize: 13 }}>
            {`(ℹ️)${t('attributes.sortingIconText')}`}
          </Typography>
        </Tooltip>
      ) : null}
      {tableName === TABLE_NAMES.EditableLegLevelGrid ? (
        <Button
          style={{ color: '#69bc46' }}
          startIcon={<SaveAltIcon />}
          color="primary"
          size="small"
          onClick={onExportLoadingList}
        >
          {t('actions.export', { item: 'Loading list' })}
        </Button>
      ) : null}
      {typeof showRowCountOnHeader !== 'undefined' ? (
        <Typography
          style={{
            position: 'absolute',
            right: '5px',
            fontSize: '0.875rem',
          }}
        >
          {t('attributes.totalRows', {
            rowCount: showRowCountOnHeader,
          })}
        </Typography>
      ) : null}
      {toggleRowHeight ? (
        <Checkbox
          onChange={toggleRowHeight.onToggleRowHeight}
          checked={toggleRowHeight.isChecked}
          icon={<Expand color="success" style={{ color: '#69bc46' }} />}
          checkedIcon={<Compress color="action" />}
          title={
            toggleRowHeight.isChecked
              ? t('attributes.compactRowHeight')
              : t('attributes.dynamicRowHeight')
          }
          inputProps={{
            'aria-label': toggleRowHeight.isChecked
              ? t('attributes.compactRowHeight')
              : t('attributes.dynamicRowHeight'),
          }}
        />
      ) : null}
    </GridToolbarContainer>
  );
}
