import {
  debounce,
  makeStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  useGetLocationForSelectLazyQuery,
  useGetLocationsForLegLazyQuery,
} from '../../generated/graphql';
import clsx from 'clsx';

interface SelectLocationProps {
  name: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  smallFontSize?: boolean;
  noLabel?: boolean;
  smallPadding?: boolean;
  withLink?: boolean;
  shortAddress?: boolean;
  mediumPadding?: boolean;
}

interface SelectLocationInterface {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  country: string;
  city: string;
  card: {
    name: string;
    type: string;
    id: number;
  };
}

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
    },
  },
});

function getLocationInfo(
  location: SelectLocationInterface | null,
  short: boolean,
) {
  if (location?.name === 'BLANK') {
    return '';
  } else {
    if (short) {
      return `${location?.name} | ${
        location?.city
      } | ${location?.country?.toUpperCase()}`;
    } else {
      return `${location?.name} | ${location?.address} | ${
        location?.postalCode
      } | ${location?.city} | ${location?.country?.toUpperCase()}`;
    }
  }
}

export function SelectLocation(props: SelectLocationProps & TextFieldProps) {
  const {
    name,
    readOnly = false,
    fullWidth = false,
    size = 'medium',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    withLink = false,
    shortAddress = false,
    mediumPadding = false,
  } = props;
  const { url } = useRouteMatch();
  const [terminal] = useState(url ? url.startsWith('/terminal/') : false);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<SelectLocationInterface[]>([]);

  const { getFieldProps } = useFormikContext();
  const [value, setValue] = useState<SelectLocationInterface | null>(null);
  const [getLocation, { data: locationData, loading }] =
    useGetLocationForSelectLazyQuery();
  const { t } = useTranslation();
  const initialValue = getFieldProps(name).value;
  const classes = useStyles();
  useEffect(() => {
    if (initialValue != null) {
      getLocation({ variables: { locationId: initialValue } });
    }
  }, [initialValue]);

  const [getLocationsforLeg, { data: optionsData, loading: loadingOptions }] =
    useGetLocationsForLegLazyQuery();

  useEffect(() => {
    if (search.length > 2) {
      getLocationsforLeg({ variables: { locationsSearch: search } });
    }
  }, [search]);

  useEffect(() => {
    if (locationData) {
      setOptions([locationData.location]);
      setValue(locationData.location);
    }
  }, [locationData]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.locations]);
      } else {
        setOptions(optionsData.locations);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  if (readOnly) {
    const textComponent = (
      <TextField
        {...props}
        variant="outlined"
        className={props.className}
        label={noLabel ? undefined : t(`attributes.${label}`)}
        value={getLocationInfo(value, shortAddress)}
        size={size}
        inputProps={{
          style: {
            fontSize: smallFontSize ? '12px' : undefined,
            padding: smallPadding ? '4px' : mediumPadding ? '7px' : undefined,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
    );
    if (withLink)
      return (
        <Link
          to={
            terminal
              ? `/terminal/cards/${value?.card.type.toLocaleLowerCase()}s/${
                  value?.card.id
                }`
              : `/traffic/cards/${value?.card.type.toLocaleLowerCase()}s/${
                  value?.card.id
                }`
          }
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(props.className, classes.link)}
        >
          {textComponent}
        </Link>
      );
    return textComponent;
  }
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <Autocomplete
          {...field}
          value={value}
          multiple={false}
          fullWidth={fullWidth}
          autoHighlight
          size={size}
          onChange={(_event, val) =>
            form.setFieldValue(name, val == null ? 0 : val?.id)
          }
          onInputChange={debouncedChangeHandler}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => getLocationInfo(option, shortAddress)}
          options={options}
          loading={loading || loadingOptions}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size={size}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              variant="outlined"
              label={noLabel ? undefined : t(`attributes.${label}`)}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: smallFontSize ? '12px' : undefined,
                  padding: smallPadding
                    ? '0'
                    : mediumPadding
                    ? '2px'
                    : undefined,
                },
              }}
            />
          )}
        />
      )}
    </Field>
  );
}
