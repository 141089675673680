import { add, format, isMatch, parse, sub } from 'date-fns';
import { Maybe } from '../generated/graphql';

// ===========================================================================
// ===========================================================================
// IMPORTANT: Any changes to this file should be updated in date_time.ts
// in server
// ===========================================================================
// ===========================================================================

export const DATE_FORMAT = 'yyyy-MM-dd';

export function parseDateString(dateString: string) {
  return parse(dateString, DATE_FORMAT, new Date());
}

export function getTimeFromDateString(referenceDate: Date, timeString: string) {
  if (isMatch(timeString, 'HH:mm:ss')) {
    return parse(timeString, 'HH:mm:ss', referenceDate);
  } else {
    return parse(timeString, 'HH:mm', referenceDate);
  }
}

export function addDaysToDateString(dateString: string, days: number) {
  return format(add(parseDateString(dateString), { days: days }), DATE_FORMAT);
}

export function subDaysToDateString(dateString: string, days: number) {
  return format(sub(parseDateString(dateString), { days: days }), DATE_FORMAT);
}

export function timeOrNull(
  dateString: Maybe<string> | undefined,
): string | null {
  return typeof dateString === 'string' &&
    (isMatch(dateString, 'HH:mm') || isMatch(dateString, 'HH:mm:ss'))
    ? dateString
    : null;
}

export function isPastDate(dateToCheck: string): boolean {
  if (
    typeof dateToCheck === 'string' &&
    parse(dateToCheck, DATE_FORMAT, new Date()) < new Date()
  ) {
    return true;
  }
  return false;
}
