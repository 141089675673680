import { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useAccess } from './lib/useAccess';
import { apolloClient } from './lib/apollo/client';
import { HttpClientProvider } from './providers/HttpClientProvider';
import { ApolloProvider } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { UserConfigurationProvider } from './providers/UserConfigurationProvider';
import NexusHomePage from './HomePage';
import CustomerPage from './views/CustomerPage';
const CustomsPage = lazy(() => import('./views/CustomsPage'));
const TerminalPage = lazy(() => import('./views/TerminalPage'));
const TrafficPage = lazy(() => import('./views/TrafficPage'));

export function AuthenticatedUser() {
  const { instance } = useMsal();

  const client = apolloClient(instance);

  return (
    <ApolloProvider client={client}>
      <UserConfigurationProvider client={client}>
        <AuthenticatedAndFetchedUser />
      </UserConfigurationProvider>
    </ApolloProvider>
  );
}

function AuthenticatedAndFetchedUser() {
  const { terminal, traffic, customsOfficer } = useAccess();

  return (
    <HttpClientProvider>
      <Router>
        <Switch>
          <Route path="/terminal">
            <Suspense fallback={<div>loading...</div>}>
              <TerminalPage />
            </Suspense>
          </Route>

          <Route path="/customer">
            <Suspense fallback={<div>loading...</div>}>
              <CustomerPage />
            </Suspense>
          </Route>

          <Route path="/traffic">
            {traffic ? (
              <Suspense fallback={<div>loading...</div>}>
                <TrafficPage />
              </Suspense>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/customs">
            {customsOfficer ? (
              <Suspense fallback={<div>loading...</div>}>
                <CustomsPage />
              </Suspense>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/">
            <NexusHomePage
              terminal={terminal}
              traffic={traffic}
              customsOfficer={customsOfficer}
            />
          </Route>
        </Switch>
      </Router>
    </HttpClientProvider>
  );
}
