import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import '../src/i18n/i18n';
import './license';
import ErrorBoundary from './errorBoundary';
import { Typography, Stack } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';

const BLOCK_UI_FOR_MAINTANANCE = false;

if (window.location.hash !== '') {
  console.warn('##Login WLH=', window.location.hash);
} else {
  ReactDOM.render(
    <React.StrictMode>
      {BLOCK_UI_FOR_MAINTANANCE ? (
        <div
          style={{
            maxHeight: 250,
            minWidth: 300,
            border: '1px solid orange',
            alignContent: 'center',
            marginTop: 10,
            padding: 10,
          }}
        >
          <Stack alignItems={'center'}>
            <EngineeringIcon
              style={{
                minHeight: 50,
                minWidth: 50,
                color: 'orange',
              }}
            />
            <Typography
              style={{ fontSize: 15, margin: 10, fontWeight: 'bold' }}
            >
              Application is under planned maintanance!
            </Typography>
            <Typography style={{ fontSize: 12, margin: 10 }}>
              Please contact admin/developer for more information.
            </Typography>
          </Stack>
        </div>
      ) : (
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      )}
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
reportWebVitals();
