import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GridOnIcon from '@material-ui/icons/GridOn';
import SummarizeIcon from '@mui/icons-material/Summarize';
import InfoIcon from '@material-ui/icons/Info';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BreadCrumbs from '../components/BreadCrumbs';
import { Layout } from '../components/Layout';
import { FixedTrafficOverview } from './FixedTrafficOverview';
import { ManualCMR } from './ManualCMR';
import { TruckFillAndTime } from './TruckFillAndTime';
import { ViewCustomer } from './ViewCustomer';
import { ViewSubcontractor } from './ViewSubcontractor';
import { ViewTerminal } from './ViewTerminal';
import { ViewCheckpoint } from './ViewCheckpoint';
import { ViewReportFromTerminal } from './ViewReportFromTerminal';
import { RemainingGoodsInTerminal } from './RemainingGoodsInTerminal';
import { ReportToTrafficInTerminal } from './ReportToTrafficInTerminal';

const links = [
  {
    text: `Truck fill and time`,
    path: '/customer',
    Icon: LocalShippingIcon,
  },
  {
    text: `Information from Traffic`,
    path: '/customer/info-from-traffic',
    Icon: InfoIcon,
  },
  {
    text: `Fixed Traffic Overview`,
    path: '/customer/fixed-traffic-overview',
    Icon: CalendarTodayIcon,
  },
  {
    text: 'CMR',
    path: '/customer/cmr',
    Icon: GridOnIcon,
  },
  {
    text: 'Remaining Goods',
    path: '/customer/remaining-goods',
  },
  {
    text: 'Report to traffic',
    path: '/customer/report-to-traffic',
    Icon: SummarizeIcon,
  },
];

interface CustomerPageContentProps {
  user?: {
    internalTerminal?: Maybe<{
      id: number;
      name: string;
      terminal: {
        locations: Array<{ id: number; country: string }>;
      };
    }>;
  };
}

export function CustomerPageContent() {
  const user: CustomerPageContentProps['user'] = {
    internalTerminal: {
      id: 81,
      name: 'ELGIGANTEN LOGISTIK',
      terminal: {
        locations: [
          {
            id: 294,
            country: 'se',
          },
        ],
      },
    },
  };
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  return (
    <Layout drawerLinks={links} pageName={t('pages.customer.title')}>
      <BreadCrumbs />
      <Switch>
        {user && user.internalTerminal != null && (
          <>
            <Route path={`${path}/`} exact>
              <TruckFillAndTime terminal={user?.internalTerminal} />
            </Route>
            <Route path={`${path}/info-from-traffic`} exact>
              <ViewReportFromTerminal
                terminal={{
                  id: user.internalTerminal.id,
                  name: user.internalTerminal.name,
                  shortCountryCode:
                    user.internalTerminal.terminal.locations.length > 0
                      ? user.internalTerminal.terminal.locations[0].country
                      : '',
                }}
              />
            </Route>
            <Route path={`${path}/fixed-traffic-overview`} exact>
              <FixedTrafficOverview terminal={user?.internalTerminal} />
            </Route>
            {/* CMR */}
            <Route path={`${path}/cmr`}>
              <ManualCMR />
            </Route>
            {/* Remaining goods */}
            <Route path={`${path}/remaining-goods`} exact>
              <RemainingGoodsInTerminal terminal={user?.internalTerminal} />
            </Route>
            {/* ReportToTraffic */}
            <Route path={`${path}/report-to-traffic`} exact>
              <ReportToTrafficInTerminal terminal={user?.internalTerminal} />
            </Route>
            {/* <Route path={`${path}/change-terminal`}>
              <SelectTerminal />
            </Route> */}
            <Route exact path={`${path}/cards/terminals/:id`}>
              <ViewTerminal terminal />
            </Route>
            <Route exact path={`${path}/cards/customers/:id`}>
              <ViewCustomer terminal />
            </Route>
            <Route exact path={`${path}/cards/subcontractors/:id`}>
              <ViewSubcontractor terminal />
            </Route>
            <Route exact path={`${path}/cards/checkpoints/:id`}>
              <ViewCheckpoint terminal />
            </Route>
          </>
        )}
      </Switch>
    </Layout>
  );
}
