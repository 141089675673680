import { Box } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { GridApiRef, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  inputValTime: {
    height: 15,
    width: 60,
    fontSize: 15,
  },
}));

interface TimePickerEditCellProps {
  apiRef: GridApiRef;
  onTimePickerEdit?: (time: string) => void;
  disabled?: boolean;
}

export function TimePickerEditCell(
  props: TimePickerEditCellProps & GridRenderEditCellParams,
) {
  const { id, field, value, apiRef, disabled = false } = props;
  const classes = useStyles();

  const [selectedDate, handleDateChange] = useState(
    value && value !== '' ? `1-1-2022 ${value}` : '',
  );

  const doUpdateCellValue = (newTime: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newTime });
    props.onTimePickerEdit?.(newTime);
  };

  const handleChange = React.useCallback(doUpdateCellValue, []);

  return (
    <Box className={classes.root}>
      <KeyboardTimePicker
        placeholder="00:00"
        mask="__:__"
        KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
        value={selectedDate === '' ? null : selectedDate}
        InputProps={{
          classes: { input: classes.inputValTime },
          disableUnderline: true,
          maxRows: 1,
        }}
        invalidDateMessage={'Invalid'}
        ampm={false}
        autoFocus
        disabled={disabled}
        onChange={(date) => {
          handleDateChange(date ? date.toString() : '');
          if (date && date.toString() !== 'Invalid Date') {
            const dt = new Date(date);
            handleChange(format(dt, 'HH:mm'));
          } else if (date == null) {
            handleChange('');
          }
        }}
      />
    </Box>
  );
}
