import { useMsal } from '@azure/msal-react';
import {
  AppBar,
  Avatar,
  Button,
  createStyles,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import Logout from '@mui/icons-material/Logout';
import { Popper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import clsx from 'clsx';
import React, { Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Maybe } from '../generated/graphql';
import { useAccess } from '../lib/useAccess';
import { getUserInitials, getUserName } from '../lib/useRoles';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { DialogModal } from './DialogModal';
import { BookmarksModal } from './modal/BookmarksModal';
import { ReactComponent as BoxIcon } from '../svgIcons/goodsbox.svg';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.1)',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(104,188,70,.2)',
      },
    },
    root: {
      display: 'flex',
      height: '100%',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#68BC46',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    active: {
      color: '#69bc46',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1.5, 3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      maxHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    childContent: {
      position: 'relative',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

interface DrawerLink {
  text: string;
  path: string;
  Icon?: any;
}

interface LayoutProps {
  pageName?: string;
  drawerLinks?: Maybe<DrawerLink>[];
  children: NonNullable<ReactNode>;
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function Layout(props: LayoutProps) {
  const { drawerLinks } = props;
  const classes = useStyles();
  const url = window.location.href;
  const isCustomer = url.includes('customer');
  const hasDrawerLinks = drawerLinks != null;
  const links: DrawerLink[] = hasDrawerLinks
    ? (drawerLinks || []).filter(notEmpty)
    : [];
  const [drawerOpen, setDrawerOpen] = useState(hasDrawerLinks);
  const theme = useTheme();
  const { terminal, traffic, customsOfficer } = useAccess();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuId = 'account-menu';
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  const { t } = useTranslation();
  const { clearConfiguration } = useUserConfiguration();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectTerminal = () => {
    history.push('/terminal/change-terminal');
    setAnchorEl(null);
  };

  const handleClearUserConfiguration = () => {
    clearConfiguration();
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const user = getUserName();

  const { pathname } = useLocation();
  const { instance } = useMsal();
  const [checked, setChecked] = useState(
    localStorage.getItem('collapseTruckSections_' + user) === 'true'
      ? true
      : false,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    localStorage.setItem(
      'collapseTruckSections_' + user,
      JSON.stringify(event.target.checked),
    );
    setAnchorEl(null);
  };
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorE2(anchorE2 ? null : event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled>{user}</MenuItem>
      <Divider />
      {terminal && !isCustomer ? (
        <MenuItem onClick={handleSelectTerminal}>
          {t('menu.changeTerminal')}
        </MenuItem>
      ) : null}
      <MenuItem
        onClick={() => {
          setOpenDeleteDialog(true);
        }}
      >
        {t('menu.clearConfig')}
      </MenuItem>
      {terminal ? (
        <MenuItem>
          {`${t('menu.collapseTruckSections')}`}

          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </MenuItem>
      ) : null}
      <MenuItem
        onClick={() => {
          localStorage.clear();
          instance.logoutRedirect({
            onRedirectNavigate: () => {
              // Stops navigation after local logout
              return false;
            },
          });
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t('button.logOut')}
      </MenuItem>
      <DialogModal
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        contentText={t('dialog.userConfig')}
        doAction={() => {
          handleClearUserConfiguration();
        }}
        buttonText={t('button.continue')}
      />
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          {drawerLinks && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, drawerOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid container>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" noWrap align="left">
                Nexus{props.pageName ? ` - ${props.pageName}` : ''}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="flex-end" alignItems="center">
                {traffic && pathname && pathname.startsWith('/traffic') && (
                  <Tooltip
                    title={`${t('actions.viewItem', {
                      item: t('resource.bookmark.plural'),
                    })}`}
                  >
                    <IconButton onClick={handleClick}>
                      <BookmarksIcon color="action" />
                    </IconButton>
                  </Tooltip>
                )}

                {terminal && (
                  <Button
                    style={{ fontSize: '12px' }}
                    color="inherit"
                    component={RouterLink}
                    to="/terminal"
                  >
                    {t('resource.terminal.capitalized')}
                  </Button>
                )}
                {traffic && (
                  <Button
                    style={{ fontSize: '12px' }}
                    color="inherit"
                    component={RouterLink}
                    to="/traffic"
                  >
                    {t('resource.traffic.capitalized')}
                  </Button>
                )}
                {customsOfficer && (
                  <Button
                    style={{ fontSize: '12px' }}
                    color="inherit"
                    component={RouterLink}
                    to="/customs"
                  >
                    {t('resource.customs.capitalized')}
                  </Button>
                )}
                <Button
                  style={{ fontSize: '12px' }}
                  color="inherit"
                  component={RouterLink}
                  to="/customer"
                >
                  {t('resource.customer.capitalized')}
                </Button>
                <Divider
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                  orientation="vertical"
                  flexItem
                />
                <IconButton
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                >
                  <Avatar
                    style={{
                      height: '30px',
                      width: '30px',
                      fontSize: '15px',
                    }}
                    alt={user}
                  >
                    {getUserInitials()}
                  </Avatar>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {traffic && pathname && pathname.startsWith('/traffic') && (
        <Popper
          id={'Bookmarks-popper'}
          open={Boolean(anchorE2)}
          anchorEl={anchorE2}
        >
          <BookmarksModal />
        </Popper>
      )}
      {renderMenu}
      {props.drawerLinks && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {links.map(({ text, path, Icon }) => (
              <Fragment key={text}>
                <ListItem button component={RouterLink} to={path}>
                  {Icon && (
                    <ListItemIcon
                      className={pathname === path ? classes.active : ''}
                    >
                      <Icon />
                    </ListItemIcon>
                  )}
                  {text === 'Remaining Goods' && (
                    <ListItemIcon
                      className={pathname === path ? classes.active : ''}
                    >
                      <BoxIcon
                        fill={pathname === path ? '#69bc46' : 'grey'}
                        height={34}
                        width={34}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={text}
                    className={pathname === path ? classes.active : ''}
                  />
                </ListItem>
                {text === 'Report to traffic' && (
                  <Divider
                    orientation="horizontal"
                    style={{ height: 3, width: '100%', marginTop: 40 }}
                  />
                )}
              </Fragment>
            ))}
          </List>
        </Drawer>
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen || !hasDrawerLinks,
        })}
      >
        <div className={classes.drawerHeader} />
        <div className={classes.childContent}>{props.children}</div>
      </main>
    </div>
  );
}
