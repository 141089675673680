import { Box, Typography, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Link, Redirect } from 'react-router-dom';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import InfoIcon from '@mui/icons-material/Info';
import nexus_homepage from './images/nexus_homepage.png';
import custom_icon from './images/custom_icon.png';
import report_icon from './images/report_icon.png';
import terminal_icon from './images/terminal_icon.png';
import traffic_icon from './images/traffic_icon.png';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

const RootContainer = styled(Box)({
  position: 'relative',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const BackgroundImage = styled('img')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -1,
});

const ButtonIcon = styled('img')({
  height: '20px',
  width: '20px',
  marginBottom: '5px',
});

const SectionButton = styled(Button)({
  width: '100px',
  height: '100px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10px',
  marginTop: '100px',
  textTransform: 'none',
  backgroundColor: '#fff',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
  position: 'relative',
});

const OverlayText = styled(Typography)({
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  color: '#FF0000',
  fontSize: '12px',
  zIndex: 1,
});

const GuideLinks = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  padding: '10px',
  margin: '50px 50px 30px',
  color: '#000',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '5px',
  cursor: 'pointer',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
});

interface homePageProps {
  terminal: boolean;
  traffic: boolean;
  customsOfficer: boolean;
}
export default function NexusHomePage(props: homePageProps) {
  const { t } = useTranslation();
  const { terminal, traffic, customsOfficer } = props;
  return (
    <RootContainer>
      <BackgroundImage src={nexus_homepage} alt="Background" />
      {/* Buttons Section */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          {terminal && !traffic && <Redirect to="/terminal" />}
          <Grid item>
            {traffic && (
              <Link to="/traffic" style={{ textDecoration: 'none' }}>
                <SectionButton variant="contained">
                  <ButtonIcon src={traffic_icon} alt="trafficIcon" />
                  <Typography variant="h6" color="#68BC46">
                    {t('pages.traffic.title')}
                  </Typography>
                </SectionButton>
              </Link>
            )}
          </Grid>
          <Grid item>
            {terminal && (
              <Link to="/terminal" style={{ textDecoration: 'none' }}>
                <SectionButton variant="contained">
                  <ButtonIcon src={terminal_icon} alt="terminalIcon" />
                  <Typography variant="h6" color="#68BC46">
                    {t('pages.terminal.title')}
                  </Typography>
                </SectionButton>
              </Link>
            )}
          </Grid>
          <Grid item>
            {customsOfficer && (
              <Link to="/customs" style={{ textDecoration: 'none' }}>
                <SectionButton variant="contained">
                  <ButtonIcon src={custom_icon} alt="customIcon" />
                  <Typography variant="h6" color="#68BC46">
                    {t('pages.customs.title')}
                  </Typography>
                </SectionButton>
              </Link>
            )}
          </Grid>
          <Grid item>
            <Link to="/customer" style={{ textDecoration: 'none' }}>
              <SectionButton variant="contained">
                <IconButton color="default" aria-label="open drawer">
                  <ManageAccountsIcon />
                </IconButton>
                <Typography variant="h6" color="#68BC46">
                  {t('pages.customer.title')}
                </Typography>
              </SectionButton>
            </Link>
          </Grid>
          <Grid item>
            <SectionButton variant="contained" disabled>
              <Typography variant="h6" color="#68BC46">
                {t('pages.reports.title')}
              </Typography>
              <OverlayText variant="caption">
                {t('pages.reports.inProgress')}
              </OverlayText>
              <ButtonIcon src={report_icon} alt="ReportsIcon" />
            </SectionButton>
          </Grid>
        </Grid>

        {/* Corner Links Section */}
        <Box display="flex" justifyContent="center">
          <GuideLinks
            onClick={() =>
              window.open(
                'https://postennorge.sharepoint.com/:u:/r/teams/NexusProduction/SitePages/News-%26-Updates.aspx?csf=1&web=1&e=VVhxvf',
                '_blank',
              )
            }
            style={{ color: '#68BC46' }}
          >
            <NewReleasesIcon />
            <Typography fontWeight={'bold'} variant="body2">
              {t('pages.switch.guideLinks.whatIsNew')}
            </Typography>
          </GuideLinks>

          <GuideLinks
            onClick={() =>
              window.open(
                'https://postennorge.sharepoint.com/:u:/r/teams/NexusProduction/SitePages/ACCESS---NEXUS.aspx?csf=1&web=1&e=oO1X6h',
                '_blank',
              )
            }
            style={{ color: '#68BC46' }}
          >
            <LockOpenIcon color="success" />
            <Typography fontWeight={'bold'} variant="body2">
              {t('pages.switch.guideLinks.howToGetAccess')}
            </Typography>
          </GuideLinks>

          <GuideLinks
            onClick={() =>
              window.open(
                'https://postennorge.sharepoint.com/:u:/r/teams/NexusProduction/SitePages/HOW-TO---NEXUS.aspx?csf=1&web=1&e=tMdRR0',
                '_blank',
              )
            }
            style={{ color: '#68BC46' }}
          >
            <InfoIcon color="success" />
            <Typography fontWeight={'bold'} color="#68BC46" variant="body2">
              {t('pages.switch.guideLinks.learnMore')}
            </Typography>
          </GuideLinks>
        </Box>
      </Box>
    </RootContainer>
  );
}
